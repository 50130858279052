"use client";
import { Button } from "@/components/ui/button";
import PrivateSaleForm from "../partials/PrivateSaleForm";
import CirclesPattern from "../BG/CirclesPattern";
import SkeletonTop from "../partials/SkeletonTop";
import { Badge } from "@/components/ui/badge";
import { BscScanLogo } from "@/components/shared/ScanPlatformsInfo";
import {
  ArrowRight,
  Currency,
  DollarSign,
  FileBadge2,
  Stars,
} from "lucide-react";
import CopyToClipboardButton from "@/components/shared/CopyToClipboardButton";
import Link from "next/link";

const HeroSection = () => {
  return (
    <>
      {/* <BackgroundBeams /> */}
      <CirclesPattern />
      <div className="website-container relative pb-10 pt-16 md:py-24">
        <div className=" w-full relative overflow-hidden">
          <section className="grid grid-cols-2 w-full h-full gap-20">
            <div className=" col-span-2 lg:col-span-1 flex flex-col w-full h-full justify-center items-start gap-14 md:gap-20">
              <SkeletonTop />
              {/* <AINumbers /> */}
              <div className="flex flex-col gap-10">
                <div className="flex flex-col gap-2">
                  <div className="w-full bg-accentViolet h-6 rounded flex justify-between items-center text-xs px-2 custom-motion-preset-blur-up-lg">
                    <p className=" text-background dark:text-foreground font-semibold flex items-center gap-1">
                      <Stars className="w-4" />
                      Smart Contract Creator is live now !
                    </p>
                    <Link href={"/dashboard"} passHref>
                      <Button
                        className="text-xs text-accent flex gap-1 items-center"
                        size={"sm"}
                        variant={"linkHover2"}
                      >
                        Try Now
                        <ArrowRight className="w-4" />
                      </Button>
                    </Link>
                  </div>
                  <div className="w-full bg-accent h-6 rounded flex justify-between items-center text-xs px-2 custom-motion-preset-blur-up-lg">
                    <p className=" text-accentViolet font-semibold flex items-center gap-1">
                      <DollarSign className="w-4" />
                      White List Sale is live now !
                    </p>
                  </div>
                </div>
                <h1
                  className={`text-3xl md:text-5xl 
                  text-center lg:text-left font-semibold text-foreground md:leading-[80px] uppercase custom-motion-preset-blur-up-lg `}
                >
                  Intelliverse <br /> Crypto Platform Technology Group
                  {/* <span className=" ml-2 px-2 py-1 bg-secondary rounded-lg ">
                    ICPTG
                  </span> */}
                </h1>
                <p
                  className={`
                text-muted-foreground 
                text-center lg:text-left
                text-lg custom-motion-preset-blur-up-lg`}
                >
                  A suite of WEB3 tools based on AI technology, allow to launch
                  and grow blockchain-powered projects efficiently.
                </p>
                <div className="w-full flex flex-col lg:flex-row items-center justify-start gap-2">
                  <p className="text-muted-foreground text-center lg:text-left text-sm lg:text-base custom-motion-preset-blur-up-lg ">
                    ICPTG Smart Contract Address <br />
                  </p>
                  <div className=" flex gap-2 justify-center md:justify-start">
                    <Button
                      variant={"outline"}
                      size={"icon"}
                      className="hover:bg-transparent custom-motion-preset-blur-up-lg md:custom-motion-slide-left"
                      onClick={() => {
                        window.open(
                          "https://bscscan.com/token/0x51af7ac8f6aacc81866a2bb1ee39f965af56e1e9",
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    >
                      <BscScanLogo className="w-5 " />
                    </Button>
                    <CopyToClipboardButton
                      textToCopy="0x51af7aC8f6aaCC81866A2bB1EE39F965AF56E1E9"
                      className="custom-motion-preset-blur-up-lg md:custom-motion-slide-left"
                    />
                    <Button
                      variant={"outline"}
                      size={"default"}
                      onClick={() =>
                        window.open(
                          "https://www.rugfreecoins.com/coin-details/25484",
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                      className="flex flex-start p-0 hover:bg-transparent hover:text-green-500 border-green-500 custom-motion-preset-blur-up-lg md:custom-motion-slide-left"
                    >
                      <span className="bg-green-500 w-12 h-full rounded-l flex justify-center items-center ">
                        <FileBadge2 className="w-5 text-white" />
                      </span>
                      <span className="w-24">Audit</span>
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row w-full gap-4">
                  <Link
                    className="w-full custom-motion-preset-blur-up-lg"
                    href={"/dashboard"}
                    passHref
                  >
                    <Button
                      variant={"shineAccent"}
                      size={"lg"}
                      className="text-md w-full relative"
                    >
                      Go to App
                      <Badge
                        variant={"outlineAccentViolet"}
                        className=" absolute right-1 top-1 text-[10px] px-1 border-transparent bg-background dark:bg-foreground text-accentViolet rounded-sm "
                      >
                        BETA
                      </Badge>
                    </Button>
                  </Link>
                  <Link
                    className="w-full custom-motion-preset-blur-up-lg"
                    href={"/ICPTG_Whitepaper.pdf"}
                    passHref
                    target="_blank"
                  >
                    <Button
                      variant={"shineAccentViolet"}
                      size={"lg"}
                      className=" text-md w-full "
                    >
                      Whitepaper
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-span-2 lg:col-span-1 hidden md:flex flex-col justify-center 
            items-center lg:items-end h-full
            "
            >
              <PrivateSaleForm />
            </div>
          </section>
        </div>
      </div>
      <div className="flex md:hidden flex-col justify-center items-center lg:items-end h-full pb-16">
        <PrivateSaleForm />
      </div>
    </>
  );
};
export default HeroSection;
