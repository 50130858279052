import {
  BingXLogo,
  Cls,
  MexcLogo,
  UptrendAgency,
} from "@/components/shared/PartnersLogos";
import { Blocks, Layers3, Rocket } from "lucide-react";

import type { JSX } from "react";

type TPartnerInfo = {
  name: string;
  description: string;
  logo: JSX.Element;
};

export const partnersData: TPartnerInfo[] = [
  {
    name: "MEXC",
    description: "Global cryptocurrency exchange",
    logo: <MexcLogo className="w-24 fill-black dark:fill-white" />,
  },
  {
    name: "BingX",
    description: "Global cryptocurrency exchange",
    logo: <BingXLogo className="w-16 fill-black dark:fill-white" />,
  },
  {
    name: "CLS",
    description: "Leading crypto trading service provider",
    logo: <Cls className="w-16 fill-black dark:fill-white" />,
  },
  {
    name: "uptrend agency",
    description: "Leading marketing partner building brand impact",
    logo: <UptrendAgency className="w-28 fill-black dark:fill-white" />,
  },
];
