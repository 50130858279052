import { useEffect, useState } from "react";

/**
 * useIsMounted hook
 * @returns {boolean} isMounted - The state of the component
 */

// Hook to check if the component is mounted
export const useIsMounted = () => {
  // Set the initial state to false
  const [isMounted, setIsMounted] = useState(false);
  // Set the state to true when the component is mounted
  useEffect(() => {
    setIsMounted(true);
  }, []);
  // Return the state
  return isMounted;
};
