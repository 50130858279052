"use client";
import { Label } from "@/components/ui/label";
import BNBPrice from "../BNBPrice";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Bnb, Usdt } from "@/components/shared/ChainsLogos";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import {
  selectBuyBtnActive,
  selectCurrencyInput,
  selectSaleIsActive,
  selectSelectedCurrency,
  setBuyBtnActive,
  setCurrencyInput,
  setSelectedCurrency,
} from "@/lib/slices/website/privateSaleSlice";
import { ChangeEvent, useState } from "react";
import { useAccount } from "wagmi";
import { currencyInputSchema } from "@/utils/ZodSchema/privateSale";

const CurrencyInput = () => {
  const dispatch = useAppDispatch();
  const { isConnected } = useAccount();
  const saleIsActive = useAppSelector(selectSaleIsActive);
  const selectedCurrency = useAppSelector(selectSelectedCurrency);
  const currencyInputStoreValue = useAppSelector(selectCurrencyInput);
  const zodBtnStateIsActive = useAppSelector(selectBuyBtnActive);
  const [zodError, setZodError] = useState("");

  const currencyInputOnChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^\d*\.?\d{0,2}$/;
    const val = event?.target.value;
    const schema = currencyInputSchema(selectedCurrency as "bnb" | "usdt");
    const zodParseResult = schema.safeParse({
      currencyInputValue: parseFloat(val) || 0,
    });
    if (!zodParseResult.success) {
      setZodError(zodParseResult?.error.issues[0].message);
      zodBtnStateIsActive && dispatch(setBuyBtnActive(false));
    } else {
      setZodError("");
      !zodBtnStateIsActive && dispatch(setBuyBtnActive(true));
    }
    if (regex.test(val)) {
      dispatch(setCurrencyInput(Number(val)));
    }
  };

  return (
    <div className="bg-secondary rounded p-4">
      <Label
        className="flex justify-between items-center py-1"
        htmlFor="currencyInput"
      >
        <p className="text-xs">You pay</p>
        {selectedCurrency === "bnb" ? (
          <BNBPrice />
        ) : (
          <span className="p-1 rounded-sm text-[12px] text-accentViolet flex items-center gap-1 h-6">
            USDT bep20
          </span>
        )}
      </Label>
      <div className="relative flex justify-between items-center">
        <Input
          type="number"
          autoComplete="off"
          autoCorrect="off"
          id="currencyInput"
          inputMode="decimal"
          pattern="^\d*\.?\d{0,2}$"
          minLength={1}
          maxLength={10}
          className=" rounded font-semibold text-3xl focus:ring-0 border-none focus:border-none bg-transparent [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none grow"
          value={currencyInputStoreValue}
          onChange={currencyInputOnChangeHandler}
          placeholder="0.00"
          disabled={!saleIsActive || !isConnected}
          readOnly={!saleIsActive}
        />
        <Select
          onValueChange={(val) => dispatch(setSelectedCurrency(val))}
          defaultValue="bnb"
        >
          <SelectTrigger className="w-44 border-none focus:rang-0 focus:outline-none select-none rounded-sm ">
            <SelectValue placeholder="Select Currency" />
          </SelectTrigger>
          <SelectContent className="border-none rounded-sm">
            <SelectGroup>
              <SelectItem value="bnb">
                <span className="flex items-center gap-1">
                  <Bnb className="w-5 h-5" />
                  BNB
                </span>
              </SelectItem>
              <SelectItem value="usdt">
                <span className="flex items-center gap-1">
                  <Usdt bep20 className="w-5 h-5" />
                  USDT
                </span>
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
        {zodError !== "" && (
          <p className=" absolute -bottom-2.5 left-0 transition-all duration-200 text-[10px] text-red-500 dark:text-red-300">
            {zodError}
          </p>
        )}
      </div>
    </div>
  );
};
export default CurrencyInput;
