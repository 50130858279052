"use client";
import { Progress } from "@/components/ui/progress";
import { useAccount, useReadContract } from "wagmi";
import { privateSaleSmartContract } from "@/wagmi/smartContract/whiteListSmartContract";
import { useEffect, useState } from "react";

type TPhaseInfo =
  | [bigint, boolean, bigint, bigint, bigint, bigint, bigint]
  | undefined;

const WhitelistProgress = () => {
  const [progressValue, setProgressValue] = useState(0);

  const { isConnected } = useAccount();

  const {
    data: participants,
    isSuccess: participantsIsSuccess,
    refetch,
  } = useReadContract({
    ...privateSaleSmartContract,
    functionName: "currentParticipants",
    args: [],
    query: {
      enabled: isConnected,
      select: (data) => (data ? Number(data) : 0),
    },
  });

  useEffect(() => {
    if (participantsIsSuccess && participants) {
      participants === 0
        ? setProgressValue(0)
        : setProgressValue((participants / 300) * 100);
    }
  }, [participants, participantsIsSuccess]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 5000);
    return () => clearInterval(interval);
  }, [refetch]);

  return (
    <div className="bg-background w-full mt-2 p-6 rounded-lg border md:border-none">
      <div className="relative flex flex-col">
        <h3 className="text-sm font-semibold mb-3 flex justify-between items-center ">
          <span>WhiteList Progress</span>
        </h3>
        <div className="flex justify-between text-xs text-muted-foreground py-1">
          <span>0</span>
          <span>{300} Users</span>
        </div>
        <div className="w-full">
          <Progress
            className="h-3"
            indicatorClassName="bg-accentViolet"
            value={progressValue || 0}
          />
          {progressValue !== 0 && (
            <div className="relative flex flex-nowrap">
              <div
                className={`spacer relative transition-all duration-1000 grow-0`}
                style={{
                  flexGrow: progressValue / 100,
                }}
              ></div>
              <p
                className={`slider relative grow-0 text-xs text-center flex justify-center items-center gap-1 mt-2`}
              >
                {participants}
                <span className="text-xs">Users</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default WhitelistProgress;
