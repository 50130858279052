"use client";

import { Button } from "../ui/button";
import { Loader, Wallet2, X } from "lucide-react";
import { useAppKit, useAppKitState, useAppKitTheme } from "@reown/appkit/react";
import { useTheme } from "next-themes";
import { useEffect } from "react";

const ConnectWalletButton = ({
  variant = "default",
  size = "default",
  className = "",
  disabled = false,
  withIcon = false,
  withLabel = true,
}: Readonly<{
  variant?:
    | "primary"
    | "default"
    | "violet"
    | "orange"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | "link"
    | "shine"
    | "shineAccent"
    | "shineAccentViolet"
    | null
    | undefined;
  size?: "default" | "sm" | "lg" | "icon" | null | undefined;
  className?: string;
  disabled?: boolean;
  withIcon?: boolean;
  withLabel?: boolean;
}>) => {
  const { setThemeMode, themeMode, setThemeVariables, themeVariables } =
    useAppKitTheme();
  const darkThemeVariables = {
    "--w3m-color-mix": "#FCDA69",
    "--w3m-accent": "#FCDA69",
    "--w3m-color-mix-strength": 2,
    "--w3m-border-radius-master": "0.05rem",
  };
  const lightThemeVariables = {
    "--w3m-color-mix": "#FCDA69",
    "--w3m-accent": "#FCDA69",
    "--w3m-color-mix-strength": 2,
    "--w3m-border-radius-master": "0.05rem",
  };

  const { theme } = useTheme();
  setThemeMode(theme === "dark" ? "dark" : "light");
  useEffect(() => {
    setThemeMode(theme === "dark" ? "dark" : "light");
    setThemeVariables(
      theme === "dark" ? darkThemeVariables : lightThemeVariables
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme, themeMode]);

  const { open } = useAppKit();
  const { open: isOpen, selectedNetworkId } = useAppKitState();

  return (
    <Button
      variant={variant}
      size={size}
      className={className}
      disabled={disabled}
      onClick={() => open()}
    >
      {isOpen ? (
        <Loader className="w-5 h-5 animate-spin" />
      ) : withIcon ? (
        <div className="flex gap-2 items-center">
          <Wallet2 size={16} />
          {withLabel && "Connect wallet"}
        </div>
      ) : (
        withLabel && "Connect wallet"
      )}
    </Button>
  );
};
export default ConnectWalletButton;
