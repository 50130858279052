"use client";
import { Card, CardContent } from "@/components/ui/card";
import SimpleTitlePara from "../SimpleTitlePara";
import { useEffect, useState } from "react";
import { useAppSelector } from "@/lib/hooks";
import {
  selectCurrentPhase,
  selectPhases,
} from "@/lib/slices/website/privateSaleSlice";
import { useAccount, useReadContract, useReadContracts } from "wagmi";
import BigNumber from "bignumber.js";
import { privateSaleSmartContract } from "@/wagmi/smartContract/privateSaleSmartContract";
import { privateSaleSmartContract as whitelistSmartContract } from "@/wagmi/smartContract/whiteListSmartContract";
import { set } from "zod";

const BalanceTab = () => {
  const currentPhase = useAppSelector(selectCurrentPhase);
  const phasePrices = useAppSelector(selectPhases);
  const { address, isConnected } = useAccount();
  const [selectedPhase, setSelectedPhase] = useState(currentPhase || 1);
  const [tokenBalance, setTokenBalance] = useState("000.00");
  const [psBalance, setPsBalance] = useState("000.00");
  const [wlBalance, setWlBalance] = useState("000.00");
  const [balanceBNB, setBalanceBNB] = useState("000.00");
  const [balanceUSDT, setBalanceUSDT] = useState("000.00");
  const [estimatedPrice, setEstimatedPrice] = useState("000.00");

  const { data: balances, isSuccess: balancesIsSuccess } = useReadContracts({
    contracts: [
      {
        ...privateSaleSmartContract,
        functionName: "getAllocatedTokensAllPhases",
        args: [address],
      },
      {
        ...whitelistSmartContract,
        functionName: "tokenAllocations",
        args: [address],
      },
    ],
  });
  useEffect(() => {
    console.log(balances);
    if (
      balances?.[0].status === "success" &&
      balances?.[1].status === "success"
    ) {
      const psBalance = new BigNumber(balances?.[0].result as BigNumber).div(
        1e18
      );
      setPsBalance(psBalance ? psBalance.toFixed(2) : "000.00");
      const wlBalance = new BigNumber(balances?.[1].result as BigNumber).div(
        1e18
      );
      setWlBalance(wlBalance ? wlBalance.toFixed(2) : "000.00");
      const totalBalance = psBalance.plus(wlBalance);
      setTokenBalance(totalBalance ? totalBalance.toFixed(2) : "000.00");
    }
  }, [balances, balancesIsSuccess]);

  // contributionPerPhase private sale
  const {
    data: contributionPerPhase,
    isSuccess: contributionPerPhaseIsSuccess,
  } = useReadContracts({
    contracts: [
      {
        ...privateSaleSmartContract,
        functionName: "bnbContributions",
        args: [address, selectedPhase],
      },
      {
        ...privateSaleSmartContract,
        functionName: "usdtContributions",
        args: [address, selectedPhase],
      },
    ],
  });
  //  whitelistContributions
  const {
    data: whitelistContributions,
    isSuccess: whitelistContributionsIsSuccess,
  } = useReadContracts({
    contracts: [
      {
        ...whitelistSmartContract,
        functionName: "userParticipationBNB",
        args: [address],
      },
      {
        ...whitelistSmartContract,
        functionName: "userParticipationUSDT",
        args: [address],
      },
    ],
  });
  useEffect(() => {
    console.log(whitelistContributions);
    if (selectedPhase === 7) {
      if (
        whitelistContributions?.[0].status === "success" &&
        whitelistContributions?.[1].status === "success"
      ) {
        const bnb = new BigNumber(
          whitelistContributions?.[0].result as BigNumber
        ).div(1e18);
        const usdt = new BigNumber(
          whitelistContributions?.[1].result as BigNumber
        ).div(1e18);
        setBalanceBNB(bnb ? bnb.toFixed(2) : "000.00");
        setBalanceUSDT(usdt ? usdt.toFixed(2) : "000.00");
      }
    } else if (
      contributionPerPhase?.[0].status === "success" &&
      contributionPerPhase?.[1].status === "success"
    ) {
      const bnb = new BigNumber(
        contributionPerPhase?.[0].result as BigNumber
      ).div(1e18);
      const usdt = new BigNumber(
        contributionPerPhase?.[1].result as BigNumber
      ).div(1e18);
      setBalanceBNB(bnb ? bnb.toFixed(2) : "000.00");
      setBalanceUSDT(usdt ? usdt.toFixed(2) : "000.00");
    }
  }, [
    contributionPerPhase,
    contributionPerPhaseIsSuccess,
    whitelistContributionsIsSuccess,
    selectedPhase,
    whitelistContributions,
  ]);

  useEffect(() => {
    if (+tokenBalance && +tokenBalance !== 0) {
      const ep = +tokenBalance * 0.3;
      const epStr = ep.toFixed(2);
      setEstimatedPrice(epStr);
    }
  }, [currentPhase, phasePrices, tokenBalance]);

  return (
    <Card className="bg-background border md:border-none">
      <CardContent className="space-y-4 pt-6  flex flex-col justify-between">
        <div className="w-full rounded flex flex-col  justify-between md:justify-start gap-4 items-center">
          <SimpleTitlePara
            title="Your Balance"
            text={`${tokenBalance}`}
            titleClassName="text-xs text-muted-foreground"
            paraClassName="text-lg"
            className="bg-secondary w-full md:basis-1/2 p-2 rounded"
            currency="ICPTG"
          />
          <div className="w-full flex gap-2">
            <SimpleTitlePara
              title="private sale balance"
              text={`${psBalance}`}
              paraClassName="text-sm"
              className="w-full md:basis-1/2 border p-1 rounded"
              currency="ICPTG"
            />
            <SimpleTitlePara
              title="Whitelist balance"
              text={`${wlBalance}`}
              paraClassName="text-sm"
              className="w-full md:basis-1/2 border p-1 rounded"
              currency="ICPTG"
            />
          </div>
          <SimpleTitlePara
            title={`Estimated Selling Price at Launch`}
            text={`${estimatedPrice}`}
            paraClassName="text-lg"
            titleClassName=""
            className="w-full md:basis-1/2 bg-secondary p-4 rounded"
            currency="USDT"
          />
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <h3 className="text-sm font-semibold"> Contribution per phase</h3>
              {/* <p className="md:text-sm text-xs">Select phase :</p> */}
            </div>
            <div
              className="grid grid-cols-3
             gap-2 md:gap-4 "
            >
              <div
                key={7}
                className={`py-2 basis-1/5 border rounded text-xs md:text-sm flex justify-center items-center cursor-pointer hover:bg-accent/80 hover:dark:text-background
                text-foreground relative disabled ${
                  7 === selectedPhase
                    ? "bg-accent/80 dark:text-background "
                    : ""
                }
                `}
                onClick={() => {
                  setSelectedPhase(7);
                }}
              >
                <span> Whitelist</span>
                <span
                  className={`text-[8px] md:text-[10px] absolute top-0 md:right-2 right-1 text-green-500 ${
                    selectedPhase == 7 ? "dark:text-background" : ""
                  } `}
                >
                  new
                </span>
              </div>
              {Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className={`py-2 basis-1/5 border rounded text-xs md:text-sm flex justify-center items-center cursor-pointer ${
                    currentPhase >= index + 1
                      ? "hover:bg-accent/80 hover:dark:text-background"
                      : "bg-secondary text-muted-foreground cursor-not-allowed"
                  } ${
                    index + 1 === selectedPhase
                      ? "bg-accent/80 dark:text-background "
                      : ""
                  } `}
                  onClick={() => {
                    if (currentPhase >= index + 1) setSelectedPhase(index + 1);
                  }}
                >
                  <span>phase {index + 1}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-between gap-4 ">
            <SimpleTitlePara
              title="USDT"
              text={`${balanceUSDT}`}
              paraClassName=""
              className=" basis-1/2 px-4 py-2 border rounded"
            />
            <SimpleTitlePara
              title="BNB"
              text={`${balanceBNB}`}
              paraClassName=""
              className=" basis-1/2 border px-4 py-2 rounded"
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
export default BalanceTab;
