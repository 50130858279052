import { Stars } from "@/components/shared/Logo";
import { MotionDiv } from "@/types/farmerMotionTypes";

const SkeletonTop = () => {
  return (
    <div className=" relative w-full bg-secondary rounded-full p-2 flex justify-between items-center gap-2 custom-motion-preset-blur-up-lg">
      <div className="rounded-full p-2 bg-background text-xs text-muted-foreground h-10 w-10 flex justify-center items-center relative ">
        <Stars className="fill-foreground !w-5" />
      </div>

      <div className="w-[calc(100%-5rem)] h-full flex flex-col justify-center items-center gap-2">
        <MotionDiv className="relative overflow-hidden rounded-full bg-background w-full h-1">
          <MotionDiv
            initial={{
              left: "-24px",
            }}
            animate={{
              left: "100%",
              transition: {
                duration: 1,
                delay: 0.5,
                repeat: Infinity,
                repeatDelay: 0.5,
                repeatType: "loop",
                ease: "easeInOut",
              },
            }}
            className=" absolute rounded-full h-1 w-6 bg-accent"
          ></MotionDiv>
        </MotionDiv>
        <MotionDiv className="relative overflow-hidden rounded-full bg-background w-full h-1">
          <MotionDiv
            initial={{
              left: "100%",
            }}
            animate={{
              left: "-24px",
              transition: {
                duration: 1,
                delay: 0.5,
                repeat: Infinity,
                repeatDelay: 0.5,
                repeatType: "loop",
                ease: "easeInOut",
              },
            }}
            className=" absolute rounded-full h-1 w-6 bg-accentViolet"
          ></MotionDiv>
        </MotionDiv>
      </div>

      <div className="rounded-full p-2 bg-background text-xs text-muted-foreground h-10 w-10 flex justify-center items-center relative">
        {/* <Stars className="fill-foreground !w-5" /> */}
        <span className="font-bold text-xs text-foreground w-5 text-center">
          AI
        </span>
      </div>
    </div>
  );
};
export default SkeletonTop;
