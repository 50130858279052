const SimpleTitlePara = ({
  title = "",
  text = "",
  className = "",
  titleClassName = "",
  paraClassName = "",
  currency = "",
}) => {
  return (
    <div className={` ${className}`}>
      <p className={`text-xs text-muted-foreground ${titleClassName}`}>
        {title ? title : "title placeholder"}
      </p>
      <p
        className={` flex justify-start items-baseline gap-0.5 ${paraClassName}`}
      >
        <span>{text ? text : "text placeholder"}</span>
        {currency !== "" && (
          <span className="text-[10px] font-bold dark:text-accent/50 text-accentViolet">
            {currency}
          </span>
        )}
      </p>
    </div>
  );
};
export default SimpleTitlePara;
