"use client";
import ConnectWalletButton from "@/components/shared/ConnectWalletButton";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useAccount } from "wagmi";
import { useDisconnect } from "wagmi";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import {
  selectAffiliateCode,
  selectSaleIsActive,
  selectTimesUp,
  setAffiliateCode,
} from "@/lib/slices/website/privateSaleSlice";
import { ArrowDown } from "lucide-react";
import { useEffect, useState } from "react";
import { useIsMounted } from "@/hooks/useIsMounted";
import BuyButtonWL from "./BuyButtonWL";
import CurrencyInputWL from "./CurrencyInputWL";
import ICPTGInputWL from "./ICPTGInputWL";

const WhiteList = () => {
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const dispatch = useAppDispatch();
  const affiliateCode = useAppSelector(selectAffiliateCode);
  const timerTimesUp: boolean = useAppSelector(selectTimesUp);
  const saleIsActive: boolean = useAppSelector(selectSaleIsActive);

  useEffect(() => {
    !isConnected && affiliateCode !== "" && dispatch(setAffiliateCode(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, address]);

  const isMounted = useIsMounted();

  return (
    <Card className="bg-background border md:border-none">
      <CardHeader>
        <CardTitle className=" text-base font-bold md:font-semibold md:text-lg flex items-center relative gap-2">
          <p className="">ICPTG WHITELIST </p>
          <p className="text-sm border border-accent dark:border-accentViolet/70 flex px-2 py-1 rounded-sm text-accentViolet dark:text-accent ">
            20%
          </p>
        </CardTitle>
        <CardDescription className=" text-xs md:text-sm flex flex-col gap-2">
          <span>
            Don’t miss out on an exclusive opportunity to join our whitelist
            sale!
          </span>
          <span className="w-full flex gap-1 flex-wrap">
            <span className="border dark:border-accentViolet/70 border-accent p-1 md:text-xs rounded-sm text-[10px] dark:text-accent text-accentViolet ">
              Minimum contribution: 2000 USDT or BNB equivalent.
            </span>
            <span className="border dark:border-accentViolet/70 border-accent p-1 md:text-xs rounded-sm text-[10px] dark:text-accent text-accentViolet ">
              Only for 300 participants.
            </span>
          </span>
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className=" relative space-y-1">
          <CurrencyInputWL />
          <ICPTGInputWL />
          <div className="w-12 h-8 absolute top-[calc(50%-2px)] left-1/2 -translate-x-1/2 -translate-y-1/2 bg-background border-4 border-background rounded-sm flex justify-center items-center">
            <ArrowDown className="w-6 h-6 text-accent" />
          </div>
        </div>
      </CardContent>
      <CardFooter
        className={`relative flex flex-col justify-center items-center ${
          isConnected && isMounted && "gap-2"
        }`}
      >
        {isMounted &&
          (isConnected ? (
            <>
              {/* <Button className="relative w-full mb-0" variant={"primary"}>
                Buy Now
              </Button> */}
              <BuyButtonWL />
              {/* <Affiliate /> */}
              <Button
                className="text-accentOrange text-xs p-0 m-0"
                variant={"link"}
                size={"sm"}
                onClick={() => disconnect()}
              >
                disconnect
              </Button>
            </>
          ) : (
            <ConnectWalletButton
              disabled={!saleIsActive}
              variant={"primary"}
              className="w-full"
            />
          ))}
      </CardFooter>
    </Card>
  );
};
export default WhiteList;
