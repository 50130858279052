"use client";
import {
  createAffiliateUser,
  getAffiliateUser,
  getAffiliateUserDetails,
} from "@/app/_actions/actions";
import CopyToClipboardButton from "@/components/shared/CopyToClipboardButton";
import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import {
  selectAffiliateCode,
  setAffiliateCode,
} from "@/lib/slices/website/privateSaleSlice";
import { CircleDollarSign, LoaderIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useReadContract } from "wagmi";
import { useAccount } from "wagmi";
import { privateSaleSmartContract } from "@/wagmi/smartContract/privateSaleSmartContract";
import BigNumber from "bignumber.js";

type TAffiliateUserDetails = {
  affiliatePercent: string | null;
};

const Affiliate = () => {
  const [hasCode, setHasCode] = useState(true);
  const [canRequestCode, setCanRequestCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isConnected, address } = useAccount();
  const affiliateCode = useAppSelector(selectAffiliateCode);
  const dispatch = useAppDispatch();
  const [affiliateUserDetails, setAffiliateUserDetails] =
    useState<TAffiliateUserDetails[]>();

  useEffect(() => {
    if (isConnected && address && affiliateCode === "") {
      const getAffiliateInfo = async () => {
        try {
          const res = await getAffiliateUser(address as string);
          res
            ? dispatch(setAffiliateCode(res?.affiliateCode))
            : setHasCode(false);
        } catch (error) {}
      };
      getAffiliateInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isConnected]);

  const requestAffiliateCode = async () => {
    try {
      setIsLoading(true);
      if (isConnected && address) {
        const newUser = await createAffiliateUser(address as string);
        if (newUser) {
          dispatch(setAffiliateCode(newUser?.affiliateCode));
          setHasCode(true);
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const getAffiliateUserInfo = async () => {
      try {
        const res = await getAffiliateUserDetails(
          address as string,
          affiliateCode,
          "2"
        );
        setAffiliateUserDetails(res);
      } catch (error) {}
    };
    if (isConnected && address && affiliateCode !== "") {
      timeout = setTimeout(() => {
        getAffiliateUserInfo();
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [isConnected, address, affiliateCode]);

  const {
    data: balance,
    isSuccess: balanceIsSuccess,
    isPending: balanceIsPending,
  } = useReadContract({
    ...privateSaleSmartContract,
    functionName: "getAllocatedTokensAllPhases",
    args: [address],
    query: {
      enabled: isConnected,
    },
  });
  useEffect(() => {
    if (balance) {
      const bn = new BigNumber(balance as BigNumber).div(1e18);
      parseFloat(bn.toFixed(2)) > 0
        ? setCanRequestCode(true)
        : setCanRequestCode(false);
    }
  }, [balance, balanceIsSuccess]);

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Button
          variant="outline"
          className="w-full flex justify-center items-center gap-2 group"
        >
          Affiliate Program
          <span className="px-1 py-0.5 bg-accent text-xs md:text-sm rounded-sm group-hover:bg-background dark:text-background group-hover:dark:text-foreground animate-pulse">
            6%
          </span>
        </Button>
      </DrawerTrigger>
      <DrawerContent className="flex justify-center items-center">
        <div className="w-[90%] md:w-full md:max-w-md">
          <DrawerHeader className="flex flex-col gap-2 w-full px-0 pt-10">
            <DrawerTitle className="flex justify-between items-center">
              Affiliate Program
              <div className="bg-accent dark:text-background px-2 py-1 flex justify-center items-center gap-1 rounded text-xs font-normal">
                <CircleDollarSign className="w-4 h-4 animate-pulse" />
                6% commission
              </div>
            </DrawerTitle>
            <DrawerDescription className="text-xs text-left ">
              Share your unique link with your community and earn commissions on
              every purchase made through your link.
            </DrawerDescription>
          </DrawerHeader>
          <div className=" flex flex-col justify-center items-center gap-4 w-full ">
            <div className="flex flex-col gap-2 w-full py-2.5">
              <span className="text-xs text-muted-foreground">
                {hasCode ? "Your Affiliate Link" : "Request Affiliate Link"}
              </span>
              {hasCode ? (
                <div className="flex gap-2 w-full">
                  <p className="text-sm bg-secondary px-2 py-1 rounded-sm flex justify-center items-center w-full">
                    {`https://intelliverse-ai.com/website?affCode=${affiliateCode}`}
                  </p>
                  <CopyToClipboardButton
                    textToCopy={`https://intelliverse-ai.com/website?affCode=${affiliateCode}`}
                  />
                </div>
              ) : !hasCode && canRequestCode ? (
                <Button
                  className="w-full"
                  variant={"primary"}
                  onClick={requestAffiliateCode}
                >
                  {isLoading ? (
                    <span className="flex gap-2">
                      <LoaderIcon className="w-4 animate-spin" /> Loading ...
                    </span>
                  ) : (
                    "Request Affiliate Link"
                  )}
                </Button>
              ) : (
                <p className="text-xs bg-red-300 dark:bg-red-500 px-2 py-1 rounded-sm flex justify-center items-center w-full">
                  To request an affiliate link, you must have participated in
                  our private sale
                </p>
              )}
            </div>
            <div className=" pb-2.5 flex justify-center items-center gap-4 w-full">
              <div className="flex flex-col items-center justify-center space-x-2 border rounded w-full h-24">
                <div className="text-4xl font-bold tracking-tighter">
                  {affiliateUserDetails?.length || 0}
                </div>
                <div className="text-[0.70rem] uppercase text-muted-foreground">
                  Link Used
                </div>
              </div>
              <div className="flex flex-col items-center justify-center space-x-2 border rounded w-full h-24">
                <div className="text-4xl font-bold tracking-tighter px-2">
                  {affiliateUserDetails
                    ?.reduce(
                      (sum, current) => sum + Number(current.affiliatePercent),
                      0
                    )
                    .toFixed(2) || 0}
                </div>
                <div className="text-[0.70rem] uppercase text-muted-foreground">
                  USDT Earned
                </div>
              </div>
            </div>
            <div className="pb-10">
              <p className="text-xs text-muted-foreground p-2 bg-secondary rounded-sm">
                <span className="text-xs pb-1 block text-foreground font-semibold">
                  Affiliate Rewards Distribution Notice
                </span>
                Please note that affiliate rewards are distributed at the
                conclusion of each private sale phase. We appreciate your
                participation and support. Stay tuned for updates following each
                phase s closure to receive your rewards.
              </p>
            </div>
          </div>
          {/* <DrawerFooter>
            <Button>Submit</Button>
          </DrawerFooter> */}
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default Affiliate;
