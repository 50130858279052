"use client";

import { useReadContract, useReadContracts } from "wagmi";
import { privateSaleSmartContract } from "@/wagmi/smartContract/privateSaleSmartContract";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import {
  selectCurrentPhase,
  selectSaleIsActive,
  setCurrentPhase,
  setSaleIsActive,
} from "@/lib/slices/website/privateSaleSlice";
const PrivateSaleLive = () => {
  //   const [currentPhase, setCurrentPhase] = useState(0);
  //   const [saleIsLive, setSaleIsLive] = useState(false);

  const currentPhase = useAppSelector(selectCurrentPhase);
  const saleIsLive = useAppSelector(selectSaleIsActive);
  const dispatch = useAppDispatch();
  const { data, isSuccess } = useReadContracts({
    contracts: [
      {
        ...privateSaleSmartContract,
        functionName: "currentPhase",
        args: [],
      },
      {
        ...privateSaleSmartContract,
        functionName: "saleActive",
        args: [],
      },
    ],
  });

  useEffect(() => {
    const currentPhaseResult = (data?.[0].result as number) ?? 0;
    const saleIsActiveResult = (data?.[1].result as boolean) ?? false;
    dispatch(setCurrentPhase(currentPhaseResult));
    dispatch(setSaleIsActive(saleIsActiveResult && currentPhaseResult > 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess]);

  return (
    <p
      className={`text-xs ${
        saleIsLive ? "text-green-500" : "text-red-500"
      } p-2 rounded-sm -mt-1 absolute -top-2 -right-2 flex items-center gap-2`}
    >
      {saleIsLive
        ? `Phase ${currentPhase} : sale is live`
        : currentPhase === 0
        ? ` Private sale not started yet `
        : `Phase ${currentPhase} : sale is off`}
      <span
        className={`w-2 h-2 ${
          saleIsLive ? "bg-green-500" : "bg-red-500"
        } rounded-full relative flex justify-center items-center`}
      >
        {saleIsLive && (
          <span className="w-2 h-2 bg-green-500 rounded-full animate-ping"></span>
        )}
      </span>
    </p>
  );
};
export default PrivateSaleLive;
