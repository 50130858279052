import BigNumber from "bignumber.js";

export const calculateTenPercent = (
  n: string,
  currency: "usdt" | "bnb",
  bnbPrice: string
) => {
  const amount = Number(n);

  const bnbPriceBN = BigNumber(bnbPrice).div(1e18).toFixed(2);

  const price = Number(bnbPriceBN);

  let result: number;

  if (currency === "usdt") {
    result = amount * 0.06;
  } else {
    result = amount * price * 0.06;
  }

  return result.toString();
};
