import { CardContent } from "@/components/ui/card";
import PrivateSalePhasesInfo from "./PrivateSalePhasesInfo";

const PhasesTab = () => {
  return (
    <CardContent className="bg-background rounded-lg h-[410px] border md:border-none ">
      <div className="grid grid-cols-2 gap-2 pt-6 rounded ">
        <PrivateSalePhasesInfo />
      </div>
    </CardContent>
  );
};
export default PhasesTab;
