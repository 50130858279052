"use client";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import getRandomIndices from "@/utils/getRandomIndexFromArray";
import { useTheme } from "next-themes";

const CirclesPattern = () => {
  const circlesPaths = [
    { cx: "825.5", cy: "225.5" },
    { cx: "825.5", cy: "375.5" },
    { cx: "825.5", cy: "525.5" },
    { cx: "825.5", cy: "675.5" },
    { cx: "825.5", cy: "825.5" },
    { cx: "825.5", cy: "975.5" },
    { cx: "975.5", cy: "225.5" },
    { cx: "975.5", cy: "375.5" },
    { cx: "975.5", cy: "525.5" },
    { cx: "975.5", cy: "675.5" },
    { cx: "975.5", cy: "825.5" },
    { cx: "975.5", cy: "975.5" },
    { cx: "1125.5", cy: "225.5" },
    { cx: "1125.5", cy: "375.5" },
    { cx: "1125.5", cy: "525.5" },
    { cx: "1125.5", cy: "675.5" },
    { cx: "1125.5", cy: "825.5" },
    { cx: "1125.5", cy: "975.5" },
    { cx: "1275.5", cy: "225.5" },
    { cx: "1275.5", cy: "375.5" },
    { cx: "1275.5", cy: "525.5" },
    { cx: "1275.5", cy: "675.5" },
    { cx: "1275.5", cy: "825.5" },
    { cx: "1275.5", cy: "975.5" },
    { cx: "1425.5", cy: "225.5" },
    { cx: "1425.5", cy: "375.5" },
    { cx: "1425.5", cy: "525.5" },
    { cx: "1425.5", cy: "675.5" },
    { cx: "1425.5", cy: "825.5" },
    { cx: "1425.5", cy: "975.5" },
    { cx: "1575.5", cy: "225.5" },
    { cx: "1575.5", cy: "375.5" },
    { cx: "1575.5", cy: "525.5" },
    { cx: "1575.5", cy: "675.5" },
    { cx: "1575.5", cy: "825.5" },
    { cx: "1575.5", cy: "975.5" },
    { cx: "1725.5", cy: "225.5" },
    { cx: "1725.5", cy: "375.5" },
    { cx: "1725.5", cy: "525.5" },
    { cx: "1725.5", cy: "675.5" },
    { cx: "1725.5", cy: "825.5" },
    { cx: "1725.5", cy: "975.5" },
    { cx: "1875.5", cy: "225.5" },
    { cx: "1875.5", cy: "375.5" },
    { cx: "1875.5", cy: "525.5" },
    { cx: "1875.5", cy: "675.5" },
    { cx: "1875.5", cy: "825.5" },
    { cx: "1875.5", cy: "975.5" },
  ];
  const [randomCircles, setRandomCircles] = useState([0, 1, 2, 3, 4]);
  useEffect(() => {
    const interval = setInterval(() => {
      const arr = getRandomIndices(circlesPaths.length, 5);
      setRandomCircles(arr);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { theme } = useTheme();
  const opacity = theme === "dark" ? [0, 0.3, 0] : [0, 1, 0];

  return (
    // [mask-image:linear-gradient(to_right,transparent,background_30%,background_70%,transparent)]
    <div
      className={cn(
        "absolute h-full w-full inset-0 overflow-hidden flex justify-center items-center z-0 [mask-image:linear-gradient(to_right,transparent,background_30%,background_70%,transparent)]"
      )}
    >
      {/* [mask-image:linear-gradient(to_bottom,transparent,background_40%,background_70%,transparent)] */}
      <div className=" absolute inset-0 overflow-hidden w-full h-full [mask-image:linear-gradient(to_bottom,transparent,background_40%,background_70%,transparent)]">
        <svg
          className="fill-none stroke-[1px] stroke-secondary dark:stroke-border absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[1950px] h-[866px] z-0"
          id="circle-svg"
          data-name="circle-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2701 1201"
        >
          <g id="circle-svg-g" data-name="circle-svg-g">
            <g id="all">
              <g id="center">
                {randomCircles.map((randomCircle) => (
                  <motion.circle
                    key={randomCircle}
                    className={"stroke-accent shadow-2xl stroke-[1px]"}
                    cx={circlesPaths[randomCircle].cx}
                    cy={circlesPaths[randomCircle].cy}
                    r="0"
                    pathLength="1"
                    initial={{
                      r: "0",
                      opacity: 0,
                      // boxShadow: "0px 0px 40px -6px rgba(255,240,102,1);",
                    }}
                    animate={{
                      r: [75, 100],
                      opacity: opacity,
                      // boxShadow: "0px 0px 40px -6px rgba(255,240,102,1);",
                    }}
                    transition={{
                      duration: 5,
                      ease: "easeInOut",
                      repeat: Infinity,
                      repeatType: "loop",
                    }}
                  />
                ))}

                <circle className="cls-1" cx="825.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="825.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="825.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="825.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="825.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="825.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="975.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="975.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="975.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="975.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="975.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="975.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="1125.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="1125.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="1125.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="1125.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="1125.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="1125.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="1275.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="1275.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="1275.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="1275.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="1275.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="1275.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="1425.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="1425.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="1425.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="1425.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="1425.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="1425.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="1575.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="1575.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="1575.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="1575.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="1575.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="1575.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="1725.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="1725.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="1725.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="1725.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="1725.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="1725.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="1875.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="1875.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="1875.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="1875.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="1875.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="1875.5" cy="975.5" r="75" />
              </g>
              <g id="around">
                <circle className="cls-1" cx="75.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="75.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="75.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="75.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="75.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="75.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="75.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="75.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="225.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="225.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="225.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="225.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="225.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="225.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="225.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="225.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="375.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="375.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="375.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="375.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="375.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="375.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="375.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="375.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="525.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="525.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="525.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="525.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="525.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="525.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="525.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="525.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="675.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="675.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="675.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="675.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="675.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="675.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="675.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="675.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="825.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="825.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="975.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="975.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="1125.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="1125.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="1275.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="1275.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="1425.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="1425.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="1575.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="1575.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="1725.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="1725.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="1875.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="1875.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="2025.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="2025.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="2025.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="2025.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="2025.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="2025.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="2025.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="2025.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="2175.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="2175.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="2175.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="2175.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="2175.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="2175.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="2175.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="2175.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="2325.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="2325.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="2325.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="2325.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="2325.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="2325.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="2325.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="2325.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="2475.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="2475.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="2475.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="2475.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="2475.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="2475.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="2475.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="2475.5" cy="1125.5" r="75" />
                <circle className="cls-1" cx="2625.5" cy="75.5" r="75" />
                <circle className="cls-1" cx="2625.5" cy="225.5" r="75" />
                <circle className="cls-1" cx="2625.5" cy="375.5" r="75" />
                <circle className="cls-1" cx="2625.5" cy="525.5" r="75" />
                <circle className="cls-1" cx="2625.5" cy="675.5" r="75" />
                <circle className="cls-1" cx="2625.5" cy="825.5" r="75" />
                <circle className="cls-1" cx="2625.5" cy="975.5" r="75" />
                <circle className="cls-1" cx="2625.5" cy="1125.5" r="75" />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};
export default CirclesPattern;
