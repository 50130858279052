import { Badge } from "@/components/ui/badge";
import {
  LaunchPadIcon,
  SmartContractCreatorIcon,
  StakingIcon,
  TokenSwapperIcon,
} from "./CustomIcons";

import type { JSX } from "react";

export type TDataEcosystem = {
  title: string;
  description: string;
  icon: JSX.Element;
  className?: string;
  badge?: JSX.Element;
};

export const dataEcosystem: TDataEcosystem[] = [
  {
    title: "Launchpad",
    description:
      "ICPTG offers a comprehensive Launchpad platform designed to facilitate project launches and token offerings. Traditionally, project teams need to navigate separate platforms for smart contract creation and launchpad services. ICPTG eliminates this redundancy by seamlessly integrating both functionalities within the same user-friendly platform. This streamlined approach saves valuable time and resources, allowing project teams to focus on building their vision. The launchpad offers additional functionalities to support projects, such as setting token sale parameters, managing fundraising goals, KYC/AML compliance, token distribution, and interacting with their investor communities.",
    icon: <LaunchPadIcon />,
    className:
      "from-secondary/20 to-accent/20 to-80% dark:from-secondary dark:to-secondary",
    badge: (
      <Badge
        className="dark:bg-transparent dark:border-accentViolet dark:text-accentViolet"
        variant={"accent"}
      >
        coming soon
      </Badge>
    ),
  },
  {
    title: "AI Smart Contract Builder and Deployer",
    description:
      "AI Smart Contract Builder and Deployer is a groundbreaking tool designed to revolutionize the process of smart contract creation and deployment. This innovative tool eliminates the need for intricate coding by leveraging the power of AI and natural language processing (NLP). Users with minimal technical knowledge can create and deploy secure smart contracts simply by providing prompts in their preferred language with unparalleled ease and efficiency.",
    icon: <SmartContractCreatorIcon />,
    className:
      "from-secondary/20 to-accentViolet/20 to-80% dark:from-secondary dark:to-secondary",
    badge: (
      <Badge
        className="dark:bg-transparent dark:border-accent dark:text-accent"
        variant={"accentViolet"}
      >
        coming soon
      </Badge>
    ),
  },
  {
    title: "AI Staking pool Builder and Deployer",
    description:
      "our platform offers a staking pool creator tool to further empower project development. The Staking Pool Creator enables project teams to effortlessly integrate staking functionalities into their own platforms directly through ICPTG's smart contract generation tools.Project teams can leverage ICPTG's user-friendly interface to configure their staking pools, allowing their token or NFT holders to participate in a secure and rewarding ecosystem. ",
    icon: <StakingIcon />,
    className:
      "from-secondary/20 to-accent/20 to-80% dark:from-secondary dark:to-secondary",
    badge: (
      <Badge
        className="dark:bg-transparent dark:border-accentViolet dark:text-accentViolet"
        variant={"accent"}
      >
        coming soon
      </Badge>
    ),
  },
  {
    title: "Crypto Swap and Exchange",
    description:
      "As part of our commitment to enhancing liquidity and accessibility within the cryptocurrency ecosystem, ICPTG introduces a Crypto Swap and Exchange platform. This integrated feature allows users to effortlessly exchange one cryptocurrency for another directly within the platform. Eliminating the need to rely on external exchanges simplifies transactions and streamlines the overall user experience. By offering competitive exchange rates and low transaction fees, we aim to maximize value for our users while minimizing barriers to entry. Our Crypto Swap and Exchange platform prioritizes security and compliance, implementing robust measures to protect user funds and ensure regulatory compliance.",
    icon: <TokenSwapperIcon />,
    className:
      "from-secondary/20 to-accentViolet/20 to-80% dark:from-secondary dark:to-secondary",
    badge: (
      <Badge
        variant={"accentViolet"}
        className="dark:bg-transparent dark:border-accent dark:text-accent"
      >
        in development
      </Badge>
    ),
  },
];
