"use client";
import bgImage from "@/assets/images/ecosystemBG.png";
import { useMotionRunning } from "@/hooks/userMotionRunning";
import { CandlestickChart, Layers3, ShieldCheck } from "lucide-react";

import { RefObject, useRef, type JSX } from "react";

type TData = {
  title: string;
  icon: JSX.Element;
};

const data: TData[] = [
  {
    title: "Intelliverse for Creators",
    icon: <Layers3 className="w-z h-7 text-accent" />,
  },
  {
    title: "Intelliverse for Owners",
    icon: <ShieldCheck className="w-z h-7 text-accent" />,
  },
  {
    title: "Intelliverse for Investors",
    icon: <CandlestickChart className="w-z h-7 text-accent" />,
  },
];

const OurValues = () => {
  const { motionState: motionState1, ref: ref1 } = useMotionRunning();
  const { motionState: motionState2, ref: ref2 } = useMotionRunning();
  const { motionState: motionState3, ref: ref3 } = useMotionRunning();
  const { motionState: motionState4, ref: ref4 } = useMotionRunning();

  const refs = [ref2, ref3, ref4];
  const motionStates = [motionState2, motionState3, motionState4];
  return (
    <div className={`relative mb-24 w-full bg-background `}>
      <div className="w-full h-full flex flex-col justify-center items-center gap-4 md:gap-8 website-container">
        <div
          ref={ref1 as RefObject<HTMLDivElement>}
          className={`relative  w-full bg-secondary rounded-lg h-96 overflow-hidden  ${motionState1}`}
          style={{
            backgroundImage: `url(${bgImage.src})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="w-full flex gap-4 flex-col md:flex-row">
          {data.map((item, index) => (
            <div
              key={index}
              ref={refs[index] as RefObject<HTMLDivElement>}
              className={`bg-secondary basis-1/3 rounded-lg px-6 py-3 md:py-6 text-sm md:text-base font-medium flex  items-center justify-between gap-2 ${motionStates[index]} `}
            >
              <span> {item.title}</span>
              <div className="w-12 h-12 md:w-16 md:h-16 rounded-full bg-background flex justify-center items-center">
                {item.icon}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default OurValues;
