import { z } from "zod";
export const currencyInputSchema = (currency: "bnb" | "usdt") =>
  z.object({
    currencyInputValue: z.number().min(currency === "bnb" ? 0.2 : 100, {
      message: `Minimum is ${currency === "bnb" ? "0.2 bnb" : "100 usdt"}`,
    }),
  });

export const currencyInputSchemaWL = (currency: "bnb" | "usdt") =>
  z.object({
    currencyInputValue: z.number().min(currency === "bnb" ? 2 : 1000, {
      message: `Minimum is ${currency === "bnb" ? "2 bnb" : "1000 usdt"}`,
    }),
  });
