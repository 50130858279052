"use client";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { ChevronUp } from "lucide-react";
import { useState } from "react";

const ScrollToTop = () => {
  const { scrollYProgress } = useScroll();
  const [opacityValue, setOpacityValue] = useState(0);
  useMotionValueEvent(scrollYProgress, "change", (progressVal) => {
    setOpacityValue(progressVal < 0.1 ? 0 : progressVal * 2);
  });

  return (
    <div
      onClick={() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        window.location.hash = "";
      }}
      className={`fixed bottom-9 right-5 z-50 justify-center items-center rounded-full select-none hover:cursor-pointer flex`}
      style={{ opacity: opacityValue }}
    >
      <div className="w-full h-full relative">
        <ChevronUp className="w-5 h-5 stroke-[2] md:stroke-[3] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-foreground " />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={`lucide lucide-circle w-16 h-16`}
        >
          <circle
            className="stroke-[1] md:stroke-[1] stroke-border fill-background"
            cx="12"
            cy="12"
            r="10"
          />
          <motion.circle
            className={`stroke-[1] md:stroke-[1] stroke-accentViolet`}
            cx="12"
            cy="12"
            r="10"
            fill="none"
            pathLength={"1"}
            strokeDashoffset={"0"}
            style={{
              pathLength: scrollYProgress,
            }}
          />
        </svg>
      </div>
    </div>
  );
};
export default ScrollToTop;
