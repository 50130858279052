function getRandomIndices(arrayLength: number, count: number) {
  // Create an array of indices [0, 1, 2, ..., arrayLength - 1]
  let indices = Array.from({ length: arrayLength }, (_, index) => index);

  // Shuffle the array of indices
  for (let i = indices.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [indices[i], indices[j]] = [indices[j], indices[i]]; // Swap elements
  }

  // Return the first 'count' indices
  return indices.slice(0, count);
}

export default getRandomIndices;
