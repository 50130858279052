import { useInView } from "framer-motion";
import { RefObject, useEffect, useRef, useState } from "react";

export const useMotionRunning = () => {
  const [motionState, setMotionState] = useState("motion-paused");
  const ref = useRef<HTMLElement>(null);
  const isInView = useInView(ref as RefObject<HTMLElement>, {
    once: true,
  });

  useEffect(() => {
    ref.current && isInView
      ? setMotionState("custom-motion-preset-blur-up-lg")
      : setMotionState("");
  }, [isInView]);

  return { ref, isInView, motionState };
};
//
