"use client";

import { useMotionRunning } from "@/hooks/userMotionRunning";
import { RefObject } from "react";

const Footer = () => {
  const { ref, motionState } = useMotionRunning();
  return (
    <div
      ref={ref as RefObject<HTMLDivElement>}
      className={`flex w-full h-full justify-center items-center py-6 border-t ${motionState}`}
    >
      <p className="text-muted-foreground text-sm">© Intelliverse 2024</p>
    </div>
  );
};
export default Footer;
