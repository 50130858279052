import { url } from "inspector";

export const BscScanLogo = ({ className = "" }) => {
  return (
    <svg
      width="122"
      height="122"
      viewBox="0 0 122 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={` ${className} `}
    >
      <path
        className="fill-[#12161C] dark:fill-white "
        d="M25.2228 57.583C25.2229 56.9056 25.3568 56.2348 25.6169 55.6093C25.877 54.9837 26.258 54.4157 26.7382 53.9378C27.2184 53.4599 27.7883 53.0816 28.4151 52.8246C29.0419 52.5676 29.7133 52.4369 30.3908 52.44L38.9588 52.468C40.3249 52.468 41.6351 53.0107 42.6011 53.9767C43.5671 54.9427 44.1098 56.2529 44.1098 57.619V90.0191C45.0748 89.7331 46.3098 89.428 47.6688 89.108C48.6103 88.8865 49.4494 88.3535 50.05 87.5953C50.6506 86.8371 50.9776 85.8983 50.9778 84.931V44.744C50.9778 43.3778 51.5204 42.0675 52.4864 41.1014C53.4524 40.1352 54.7626 39.5923 56.1288 39.592H64.7228C66.089 39.5923 67.3992 40.1352 68.3651 41.1014C69.3311 42.0675 69.8738 43.3778 69.8738 44.744V82.044C69.8738 82.044 72.0238 81.174 74.1168 80.29C74.8945 79.9611 75.5582 79.4104 76.025 78.7067C76.4918 78.003 76.7411 77.1775 76.7418 76.333V31.866C76.7418 30.5001 77.2843 29.1901 78.2501 28.2241C79.2159 27.2581 80.5258 26.7153 81.8918 26.715H90.4768C91.842 26.7164 93.1509 27.2596 94.1158 28.2255C95.0808 29.1914 95.6228 30.5008 95.6228 31.866V68.483C103.066 63.089 110.609 56.601 116.595 48.8C117.463 47.6677 118.038 46.338 118.268 44.9295C118.497 43.521 118.375 42.0776 117.911 40.728C115.14 32.7562 110.735 25.4505 104.979 19.2786C99.2225 13.1067 92.241 8.20438 84.4813 4.88543C76.7216 1.56649 68.3544 -0.0960273 59.9153 0.00428347C51.4762 0.104594 43.1509 1.96552 35.4723 5.46797C27.7936 8.97042 20.9307 14.0373 15.3226 20.3443C9.71457 26.6513 5.48488 34.0596 2.90423 42.0951C0.323578 50.1305 -0.551199 58.6163 0.33587 67.0093C1.22294 75.4022 3.85232 83.5176 8.05576 90.836C8.7881 92.0985 9.86553 93.1258 11.1615 93.7972C12.4574 94.4685 13.918 94.7561 15.3718 94.626C16.9958 94.483 19.0178 94.281 21.4218 93.999C22.468 93.88 23.434 93.3803 24.1356 92.5951C24.8373 91.8099 25.2257 90.7941 25.2268 89.741V57.583"
        fill="#12161C"
      />
      <path
        d="M25.0391 109.544C34.0883 116.127 44.7823 120.079 55.9379 120.961C67.0934 121.844 78.2758 119.623 88.2476 114.545C98.2195 109.467 106.592 101.729 112.439 92.1878C118.286 82.6465 121.38 71.6735 121.378 60.4831C121.378 59.0831 121.313 57.7051 121.22 56.3311C99.057 89.3861 58.1351 104.839 25.0391 109.544Z"
        fill="#F0B90B"
      />
    </svg>
  );
};
export const EthScanLogo = ({ className = "" }) => {
  return (
    <svg
      width="122"
      height="122"
      viewBox="0 0 122 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={` ${className} `}
    >
      <path
        d="M25.79 58.4149C25.7901 57.7357 25.9244 57.0633 26.1851 56.4361C26.4458 55.809 26.8278 55.2396 27.3092 54.7605C27.7907 54.2814 28.3619 53.9021 28.9903 53.6444C29.6187 53.3867 30.2918 53.2557 30.971 53.2589L39.561 53.2869C40.9305 53.2869 42.244 53.831 43.2124 54.7994C44.1809 55.7678 44.725 57.0813 44.725 58.4509V90.9309C45.692 90.6439 46.934 90.3379 48.293 90.0179C49.237 89.7962 50.0783 89.262 50.6805 88.5019C51.2826 87.7418 51.6102 86.8006 51.61 85.8309V45.5409C51.61 44.1712 52.154 42.8576 53.1224 41.889C54.0908 40.9204 55.4043 40.3762 56.774 40.3759H65.381C66.7506 40.3762 68.0641 40.9204 69.0325 41.889C70.0009 42.8576 70.545 44.1712 70.545 45.5409V82.9339C70.545 82.9339 72.7 82.0619 74.799 81.1759C75.5787 80.8462 76.2441 80.2941 76.7122 79.5886C77.1803 78.8832 77.4302 78.0555 77.431 77.2089V32.6309C77.431 31.2615 77.9749 29.9481 78.9431 28.9797C79.9113 28.0113 81.2245 27.4672 82.5939 27.4669H91.201C92.5706 27.4669 93.884 28.0109 94.8525 28.9794C95.8209 29.9478 96.365 31.2613 96.365 32.6309V69.3399C103.827 63.9319 111.389 57.4279 117.39 49.6069C118.261 48.4717 118.837 47.1386 119.067 45.7267C119.297 44.3148 119.174 42.8678 118.709 41.5149C115.931 33.5227 111.516 26.1983 105.745 20.0105C99.974 13.8228 92.9749 8.90785 85.1955 5.58032C77.4161 2.2528 69.0277 0.585938 60.5671 0.686416C52.1065 0.786893 43.7601 2.6525 36.062 6.16383C28.3638 9.67517 21.4834 14.7549 15.8611 21.078C10.2388 27.401 5.99842 34.8282 3.41131 42.8842C0.824207 50.9401 -0.0526487 59.4474 0.836851 67.8617C1.72635 76.276 4.36263 84.4119 8.57696 91.7489C9.31111 93.0145 10.3912 94.0444 11.6903 94.7175C12.9894 95.3906 14.4536 95.679 15.911 95.5489C17.539 95.4059 19.566 95.2029 21.976 94.9199C23.0251 94.8008 23.9937 94.2999 24.6972 93.5126C25.4008 92.7253 25.7901 91.7067 25.791 90.6509L25.79 58.4149Z"
        fill="#21325B"
      />
      <path
        d="M25.6021 110.51C34.6744 117.11 45.3959 121.072 56.5802 121.957C67.7646 122.841 78.9757 120.615 88.9731 115.523C98.9705 110.431 107.364 102.673 113.226 93.1068C119.087 83.5405 122.188 72.539 122.185 61.3197C122.185 59.9197 122.12 58.5347 122.027 57.1577C99.808 90.2957 58.7831 105.788 25.604 110.505"
        fill="#979695"
      />
    </svg>
  );
};

export const scanPlatformsInfo = [
  {
    chainId: 1,
    chainName: "Ethereum",
    chainLogo: EthScanLogo,
    isTestnet: false,
    url: "https://etherscan.io/",
  },
  {
    chainId: 56,
    chainName: "Binance Smart Chain",
    chainLogo: BscScanLogo,
    isTestnet: false,
    url: "https://bscscan.com/",
  },
  {
    chainId: 97,
    chainName: "Binance Smart Chain testnet",
    chainLogo: BscScanLogo,
    isTestnet: true,
    url: "https://testnet.bscscan.com/",
  },
  {
    chainId: 11155111,
    chainName: "Sepolia testnet",
    chainLogo: EthScanLogo,
    isTestnet: true,
    url: "https://sepolia.etherscan.io/",
  },
];
