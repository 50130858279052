export type TPhase = {
  name: string;
  priceUSDT: string;
  priceBNB: string;
  rateUSDT: string;
  rateBNB: string;
  tokensToSale: string;
  percentage?: string;
};

export const phasesData: TPhase[] = [
  {
    name: "phase 1",
    priceUSDT: "0.10",
    priceBNB: "0",
    rateUSDT: "1 = 10 icptg",
    rateBNB: "1 = 5000 icptg",
    tokensToSale: "2M ICPTG",
    percentage: "200%",
  },
  {
    name: "phase 2",
    priceUSDT: "0.15",
    priceBNB: "0",
    rateUSDT: "1 = 6.66 icptg",
    rateBNB: "0",
    tokensToSale: "2M ICPTG",
    percentage: "150%",
  },
  {
    name: "phase 3",
    priceUSDT: "0.20",
    priceBNB: "0",
    rateUSDT: "1 = 5 icptg",
    rateBNB: "1 = 2500 icptg",
    tokensToSale: "2M ICPTG",
    percentage: "100%",
  },
  {
    name: "phase 4",
    priceUSDT: "0.25",
    priceBNB: "0",
    rateUSDT: " 1 = 4 icptg",
    rateBNB: "0",
    tokensToSale: "2M ICPTG",
    percentage: "50%",
  },
  {
    name: "phase 5",
    priceUSDT: "0.30",
    priceBNB: "0",
    rateUSDT: "1 = 3.33 icptg",
    rateBNB: "0",
    tokensToSale: "2M ICPTG",
    percentage: "",
  },
];
