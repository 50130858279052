"use client";
import { useAppSelector } from "@/lib/hooks";
import SimpleTitlePara from "../SimpleTitlePara";
import { selectCurrentPhase } from "@/lib/slices/website/privateSaleSlice";
import { phasesData } from "@/Constants/data/website/phasesData";

const PrivateSalePhasesInfo = () => {
  const phases = phasesData;
  const currentPhase = useAppSelector(selectCurrentPhase);
  return (
    <>
      {phases.map((phase, index) => (
        <div
          key={index}
          className={`p-2 rounded flex flex-col items-start gap-1 col-span-2 border relative ${
            index + 1 == currentPhase
              ? "bg-green-100/50 dark:bg-secondary"
              : "bg-transparent"
          }
                `}
        >
          <div className=" flex justify-between items-center w-full">
            <h3
              className={`text-xs md:text-sm font-semibold w-full capitalize ${
                index + 1 == currentPhase ? "text-green-500" : "text-foreground"
              } `}
            >
              {phase.name}
              {index + 1 < currentPhase ? (
                <span className=" ml-2  text-red-500 text-xs capitalize border rounded-sm px-1 font-normal ">
                  Ended
                </span>
              ) : index + 1 === currentPhase + 1 ? (
                <span className=" ml-2 text-yellow-500 text-xs capitalize px-1 font-normal border rounded-sm">
                  Upcoming
                </span>
              ) : index + 1 === currentPhase ? (
                <span className=" ml-2 text-green-500 low-500 capitalize px-1 font-normal text-xs border border-green-500 rounded-sm">
                  Live
                </span>
              ) : (
                ""
              )}
            </h3>
            {phase.percentage !== "" && index + 1 >= currentPhase && (
              <span className="text-[10px] text-green-500 -mt-1 bg-background px-1">
                {phase.percentage}
              </span>
            )}
          </div>
          <div className="flex flex-row gap-4">
            <SimpleTitlePara
              title="Price"
              text={`${phase.priceUSDT} USDT`}
              titleClassName=""
              paraClassName="text-xs font-semibold md:font-normal md:text-sm"
              className="flex items-center gap-1"
            />
            <SimpleTitlePara
              title="Tokens to sale"
              text={`${phase.tokensToSale}`}
              titleClassName=""
              paraClassName="text-xs font-semibold md:font-normal md:text-sm"
              className="flex items-center gap-1"
            />
          </div>
        </div>
      ))}
    </>
  );
};
export default PrivateSalePhasesInfo;
