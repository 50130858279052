import { Stars } from "@/components/shared/Logo";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import useDebouncedValue from "@/hooks/useDebouncedValue";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import {
  selectBNBPrice,
  selectCurrencyInput,
  selectCurrentPhase,
  selectPhases,
  selectSaleIsActive,
  selectSelectedCurrency,
  selectTokensAmount,
  setCurrencyInput,
  setTokensAmount,
} from "@/lib/slices/website/privateSaleSlice";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";

const ICPTGInput = () => {
  const dispatch = useAppDispatch();
  const currencyInputStoreValue = useAppSelector(selectCurrencyInput);
  const currentPhase = useAppSelector(selectCurrentPhase);
  const currentPhasePrice =
    useAppSelector(selectPhases)[currentPhase !== 0 ? currentPhase - 1 : 0];
  const bnbPrice = useAppSelector(selectBNBPrice);
  const selectedCurrency = useAppSelector(selectSelectedCurrency);
  // const [tokensAmount, setTokensAmount] = useState("0");
  const tokensAmount = useAppSelector(selectTokensAmount);

  const calculateTokens = (_amount: number, _currency: string) => {
    let amountBN;
    const bnbPriceBN = new BigNumber(bnbPrice || 0);
    const currentPhasePriceBN = new BigNumber(
      currentPhasePrice || 0.1
    ).multipliedBy(1e18);
    amountBN = new BigNumber(_amount).multipliedBy(1e18);
    _currency == "bnb"
      ? (amountBN = amountBN.multipliedBy(bnbPriceBN).div(1e18))
      : amountBN;
    let tokens = amountBN.div(currentPhasePriceBN).toFixed(2);
    dispatch(setTokensAmount(tokens));
    // setTokensAmount(tokens);
  };

  useEffect(() => {
    calculateTokens(currencyInputStoreValue, selectedCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currencyInputStoreValue,
    selectedCurrency,
    bnbPrice,
    currentPhasePrice,
    currentPhase,
  ]);
  useEffect(() => {
    selectedCurrency === "bnb"
      ? dispatch(setCurrencyInput(1))
      : dispatch(setCurrencyInput(100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrency]);

  return (
    <div className="bg-secondary rounded p-4">
      <Label
        className="text-foreground-muted flex justify-between items-center py-1"
        htmlFor="icptg"
      >
        <p className="text-xs">You get </p>
        <p className="p-1.5 text-[12px] text-accentViolet">
          1 ICPTG = {currentPhasePrice} USDT
        </p>
      </Label>
      <div className="relative flex justify-between items-center">
        <Input
          type="number"
          autoComplete="off"
          autoCorrect="off"
          id="currencyInput"
          inputMode="decimal"
          pattern="^[0-9]*[.,]?[0-9]*$"
          minLength={1}
          maxLength={10}
          className="relative rounded font-semibold text-3xl focus:ring-0 border-none focus:border-none bg-transparent [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none grow"
          value={+tokensAmount || 0.0}
          placeholder="0.00"
          disabled={true}
          readOnly={true}
        />
        <div className="w-44 bg-background px-3 py-2.5 rounded-sm text-sm flex items-center gap-1">
          <span className=" rounded-full p-1 bg-[#F0B90B] ">
            <Stars className="w-3 h-3 fill-white" />
          </span>
          ICPTG
        </div>
      </div>
    </div>
  );
};
export default ICPTGInput;
