"use client";

import { Button } from "@/components/ui/button";
import { useMotionRunning } from "@/hooks/userMotionRunning";
import { RefObject, useState } from "react";

const Disclaimer = () => {
  const [readMore, setReadMore] = useState(false);
  const toggleReadMore = () => setReadMore(!readMore);
  const { ref, motionState } = useMotionRunning();
  return (
    <div
      ref={ref as RefObject<HTMLDivElement>}
      className={`website-container md:px-0 py-24 ${motionState} `}
    >
      <div className="w-full bg-secondary rounded-lg p-8">
        <p className="font-medium">Disclaimer</p>
        <p className="text-sm text-muted-foreground">
          Trading cryptocurrencies carries a high level of risk, and may not be
          suitable for all investors. Before deciding to trade cryptocurrency
          you should carefully consider your investment objectives, level of
          experience, and risk appetite.
          {!readMore ? "... " : ""}
          <span className={`${readMore ? "" : "hidden"}`}>
            <br />
            The possibility exists that you could sustain a loss of some or all
            of your initial investment and therefore you should not invest money
            that you cannot afford to lose. You should be aware of all the risks
            associated with cryptocurrency trading, and seek advice from an
            independent financial advisor.
            <br />
            ICOs, IEOs, STOs and any other form of offering will not guarantee a
            return on your investment. Since Any opinions, news, research,
            analyses, prices, or other information contained on this website is
            provided as general market commentary, and does not constitute
            investment advice.
            <br />
            The Intelliverse Crypto Platform Technology Group will not accept
            liability for any loss or damage, including without limitation to,
            any loss of profit, which may arise directly or indirectly from use
            of or reliance on such information. All opinions expressed on this
            site are owned by the respective writer and should never be
            considered as advice in any form.
            <br />
            The Intelliverse Crypto Platform Technology Group makes no
            representation or warranties as to the accuracy and or timelines of
            the information contained herein. A qualified professional should be
            consulted before making any financial decisions.
          </span>
          {
            <Button
              variant={"link"}
              className="py-0 px-0 h-full text-accentViolet"
              onClick={() => toggleReadMore()}
            >
              {!readMore ? "Read more" : "Read less"}
            </Button>
          }
        </p>
      </div>
    </div>
  );
};
export default Disclaimer;
