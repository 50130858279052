"use client";
import { Stars } from "@/components/shared/Logo";
import { RefObject } from "react";
import CirclePositions from "./CirclePositions";
import {
  Carousel,
  CarouselMainContainer,
  CarouselThumbsContainer,
  SliderMainItem,
  SliderThumbItem,
} from "@/components/ui/extentions/carousel";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { roadMapData } from "./data";
import { useMotionRunning } from "@/hooks/userMotionRunning";

const RoadMap = () => {
  const { ref: ref1, motionState: motionState1 } = useMotionRunning();
  const { ref: ref2, motionState: motionState2 } = useMotionRunning();
  return (
    <div
      className="w-full h-full pb-24 website-container overflow-hidden"
      id="roadMap"
    >
      <div
        className={`relative flex flex-col gap-1 w-full justify-center items-center pb-24  ${motionState1}`}
        ref={ref1 as RefObject<HTMLDivElement>}
      >
        <span className="text-accentViolet">Ecosystem</span>
        <h2 className=" text-2xl md:text-4xl font-semibold w-full text-center">
          Intelliverse RoadMap
        </h2>
        <p className="w-full md:w-1/2 text-center text-muted-foreground">
          Over the next two years, we are rolling out top-tier web3 tools and
          services. It is our plan to bring you the best in innovation and
          support. Stay tuned for an exciting journey ahead!
        </p>
      </div>
      <div className="grid grid-cols-2 gap-24 lg:gap-10">
        <div
          ref={ref2 as RefObject<HTMLDivElement>}
          className={`col-span-2 lg:col-span-1 flex justify-center items-center order-2 lg:order-none ${motionState2} `}
        >
          <Carousel
            carouselOptions={{
              align: "center",
            }}
            orientation="vertical"
            className="w-full flex [&>div:nth-child(1)]:flex-shrink-0 [&>div:nth-child(2)]:w-full "
          >
            <CarouselThumbsContainer className="h-[600px] ">
              {roadMapData.map((item, index) => (
                <SliderThumbItem
                  key={index}
                  index={index}
                  className="rounded-sm bg-transparent [&>div]:h-[194px] [&>div]:w-12 [&>div]:md:w-16 [&>div]:aspect-auto relative first:pt-0 last:pb-0 px-0"
                >
                  <div className="flex items-center justify-center w-full cursor-pointer h-full [writing-mode:vertical-rl] border bg-transparent rounded-sm text-sm p-2 md:p-4 md:text-base">
                    {item.title}
                  </div>
                </SliderThumbItem>
              ))}
            </CarouselThumbsContainer>
            <CarouselMainContainer className=" h-[600px] w-full">
              {roadMapData.map((q, index) => {
                return (
                  <SliderMainItem
                    key={index}
                    className={`basis-1/3 p-2 first:pt-0 last:pb-0 custom-motion-preset-blur-up-lg `}
                  >
                    <div className="h-[380px]">
                      <Card className="relative overflow-hidden flex flex-col h-full bg-secondary border-none p-6">
                        <CardHeader>
                          <CardTitle className="">{q.title}</CardTitle>
                        </CardHeader>
                        <CardContent className="flex h-full items-start justify-end p-6 flex-col">
                          {q.items.map((item, index) => (
                            <div
                              key={index}
                              className="flex justify-start items-center gap-2"
                            >
                              <p className=" absolute font-extrabold -top-5 right-0 text-[120px] md:text-[260px] text-background/20">
                                {q.title.slice(0, 2)}
                              </p>
                              <span>
                                <Stars className="w-4 fill-accentViolet" />
                              </span>
                              <p className=" dark:text-muted-foreground text-base">
                                {item.text}
                              </p>
                            </div>
                          ))}
                        </CardContent>
                      </Card>
                    </div>
                  </SliderMainItem>
                );
              })}
            </CarouselMainContainer>
          </Carousel>
        </div>
        <div className="relative flex justify-center items-center h-full col-span-2 lg:col-span-1">
          <CirclePositions />
          <div className=" top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 absolute flex justify-center items-center bg- w-48 h-48 rounded-full">
            <Stars className={`w-24 transition-all delay-1000 duration-500 `} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RoadMap;
