"use client";
import { useMotionRunning } from "@/hooks/userMotionRunning";
import EcosystemItem from "./EcosystemItem";
import bgImage from "@/assets/images/deep-mind.png";
import { RefObject } from "react";

const EcoSystem = () => {
  const { ref: ref1, motionState: motionState1 } = useMotionRunning();
  const { ref: ref2, motionState: motionState2 } = useMotionRunning();
  return (
    <div className="w-full" id="ecosystem">
      <div className=" relative w-full flex flex-col gap-24 justify-center items-center pb-24">
        <div
          ref={ref1 as RefObject<HTMLDivElement>}
          className={`relative flex flex-col gap-1 w-full justify-center items-center website-container rounded-lg  ${motionState1} `}
        >
          <span className="text-accentViolet">Ecosystem</span>
          <h2 className=" text-2xl md:text-4xl font-semibold w-full text-center">
            Intelliverse Ecosystem
          </h2>
          <p className="text-muted-foreground w-full md:w-1/2 text-center">
            ICPTG tackles the complexities of Web3 development with a
            comprehensive suite of AI- powered solutions designed to empower
            users and streamline workflows.
          </p>
        </div>
        <div
          ref={ref2 as RefObject<HTMLDivElement>}
          className={`website-container  ${motionState2} `}
        >
          <div
            style={{
              backgroundImage: `url(${bgImage.src})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="h-96 rounded-lg"
          ></div>
        </div>
        <div className="w-full flex flex-col gap-24 website-container">
          <EcosystemItem />
        </div>
      </div>
    </div>
  );
};
export default EcoSystem;
