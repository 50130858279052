"use client";
import ourVisionImage1 from "@/assets/images/AI.png";
import { useMotionRunning } from "@/hooks/userMotionRunning";
import { Atom, Eye } from "lucide-react";
import { RefObject } from "react";

const Vision = () => {
  const { ref: ref1, motionState: motionState1 } = useMotionRunning();
  const { ref: ref2, motionState: motionState2 } = useMotionRunning();
  const { ref: ref3, motionState: motionState3 } = useMotionRunning();
  const { ref: ref4, motionState: motionState4 } = useMotionRunning();
  return (
    <div className="w-full website-container grid grid-cols-2 mb-24 gap-20">
      <div
        ref={ref1 as RefObject<HTMLDivElement>}
        className={`flex flex-col gap-4 col-span-2 justify-center items-center ${motionState1} `}
      >
        <p className="text-sm text-accentViolet">
          Become a part of our project
        </p>
        <h1 className=" text-2xl md:text-4xl font-semibold text-center">
          Our Vision & Mission
        </h1>
      </div>
      <div
        style={{
          backgroundImage: `url(${ourVisionImage1.src})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        ref={ref2 as RefObject<HTMLDivElement>}
        className={`relative col-span-2 md:col-span-2 flex justify-center items-center rounded-lg overflow-hidden h-96 w-full ${motionState2}`}
      ></div>
      <div className=" col-span-2 md:col-span-2 flex flex-col justify-between items-start">
        <div className="grid grid-cols-2 gap-10">
          <div
            ref={ref3 as RefObject<HTMLDivElement>}
            className={`col-span-2 md:col-span-1 w-full h-full p-6 bg-secondary rounded-lg flex flex-col gap-4 ${motionState3} `}
          >
            <div className="w-12 h-12 md:w-16 md:h-16 rounded-full bg-background flex justify-center items-center">
              <Eye className="w-6" />
            </div>
            <h3 className="text-lg font-bold">Our Vision</h3>
            <p className=" text-sm md:text-base">
              ICPTG envisions a future where Web3, Blockchain, and Crypto
              technologies are readily accessible to all. We believe in a
              decentralized future powered by individual creativity and
              innovation. By dismantling technical barriers and empowering
              users, ICPTG aims to unlock the full potential of blockchain
              technology and shape a more inclusive digital landscape.
            </p>
          </div>
          <div
            ref={ref4 as RefObject<HTMLDivElement>}
            className={`col-span-2 md:col-span-1 w-full h-full p-6 bg-secondary rounded-lg flex flex-col gap-4 ${motionState4} `}
          >
            <div className="w-12 h-12 md:w-16 md:h-16 rounded-full bg-background flex justify-center items-center">
              <Atom className="w-6" />
            </div>
            <h3 className="text-lg font-bold">Our Mission</h3>

            <p className=" text-sm md:text-base">
              Our mission : is to bridge the gap between complex blockchain
              technology and everyday users. ICPTG is dedicated to developing
              user-friendly AI tools and fostering a supportive community that
              helps individuals and projects to actively participate in the
              exciting world of Web3. We are committed to driving innovation,
              streamlining development processes, and fostering a collaborative
              environment that propels the blockchain ecosystem forward.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Vision;
