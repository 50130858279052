import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/ScrollToTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/disclaimer/Disclaimer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/ecosystem/EcoSystem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/HeroSection/HeroSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/parteners/Partners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/roadMap/RoadMap.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/socialMedia/SocialMedia.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/tokenomics/Tokenomic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/Values/OurValues.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/vision/Vision.tsx");
