import { Stars } from "@/components/shared/Logo";
import { ArrowLeftRight, Code, Layers3, Rocket } from "lucide-react";

export const LaunchPadIcon = () => {
  return (
    <div
      className={`relative bg-accent/50 dark:bg-secondary w-16 h-16 rounded-full flex justify-center items-center`}
    >
      <Stars className={` w-5 fill-accentViolet absolute top-0 left-0`} />
      <Rocket className={`w-8 h-8 stroke-[1.5]  text-accentViolet`} />
    </div>
  );
};

export const SmartContractCreatorIcon = () => {
  return (
    <div
      className={`relative bg-accentViolet dark:bg-secondary w-16 h-16 rounded-full flex justify-center items-center`}
    >
      <Stars className={` w-5 fill-accent absolute top-0 left-0`} />
      <Code className={`w-8 h-8 stroke-[1.5]  text-accent `} />
    </div>
  );
};

export const StakingIcon = () => {
  return (
    <div
      className={`relative bg-accent/50 dark:bg-secondary w-16 h-16 rounded-full flex justify-center items-center`}
    >
      <Stars className={` w-5 fill-accentViolet absolute top-0 left-0`} />
      <Layers3 className={`w-8 h-8 stroke-[1.5] text-accentViolet`} />
    </div>
  );
};
export const TokenSwapperIcon = () => {
  return (
    <div
      className={`relative bg-accentViolet dark:bg-secondary w-16 h-16 rounded-full flex justify-center items-center`}
    >
      <Stars className={` w-5 fill-accent absolute top-0 left-0`} />
      <ArrowLeftRight className={`w-8 h-8 stroke-[1.5] text-accent `} />
    </div>
  );
};
