type TItem = {
  text: string;
};

type TRoadMapData = {
  title: string;
  items: TItem[];
};

export const roadMapData: TRoadMapData[] = [
  {
    title: "Q4 2023",
    items: [
      {
        text: "Project Initiation",
      },
      {
        text: "Market Research",
      },
    ],
  },
  {
    title: "Q1 2024",
    items: [
      {
        text: "Brand Development",
      },
      {
        text: "Community Engagement",
      },
      {
        text: "Content Creation",
      },
      {
        text: "Platform Development Kick-off",
      },
      {
        text: "Security & Compliance",
      },
      {
        text: "Testing & Feedback",
      },
    ],
  },
  {
    title: "Q2 2024",
    items: [
      {
        text: "Token Creation",
      },
      {
        text: "Private Sale Preparation",
      },
    ],
  },
  {
    title: "Q3 2024",
    items: [
      {
        text: "Public Sale Launch",
      },
      {
        text: "Listing on Exchanges",
      },
      {
        text: "Marketing Campaigns",
      },
    ],
  },
  {
    title: "Q4 2024",
    items: [
      {
        text: "Research & Development",
      },
      {
        text: "Beta Testing",
      },
      {
        text: "Documentation & Support",
      },
    ],
  },
  {
    title: "Q1 2025",
    items: [
      {
        text: "Tool Deployment",
      },
      {
        text: "Promotion & Adoption",
      },
      {
        text: "Continuous Improvement",
      },
    ],
  },
  {
    title: "Q2 2025",
    items: [
      {
        text: "Conceptualization",
      },
      {
        text: "Design & Development",
      },
      {
        text: "Testing & Optimization",
      },
    ],
  },
  {
    title: "Q3 2025",
    items: [
      {
        text: "Launch & Integration",
      },
      {
        text: "Market Adoption",
      },
      {
        text: "Integration with Platform",
      },
    ],
  },
  {
    title: "Q4 2025",
    items: [
      {
        text: "Cross-Platform Development",
      },
      {
        text: "Feature Planning",
      },
      {
        text: "User Testing",
      },
    ],
  },
  {
    title: "Q1 2026",
    items: [
      {
        text: "Application Release",
      },
      {
        text: "User Acquisition",
      },
      {
        text: "Strategic Partnerships",
      },
    ],
  },
];
