"use client";
import {
  MediumIcon,
  TelegramIcon,
  XIcon,
} from "@/components/shared/SocialMediaIcons";
import { useMotionRunning } from "@/hooks/userMotionRunning";
import { ArrowRight } from "lucide-react";
import { ReactNode, RefObject } from "react";

type TData = {
  name: string;
  icon: ReactNode;
  link: string;
};
const data: TData[] = [
  {
    name: "Telegram",
    icon: (
      <TelegramIcon className="w-5 h-5 fill-foreground group-hover:dark:fill-background group-hover:fill-white transition-colors duration-200" />
    ),
    link: "https://t.me/ICPTG",
  },
  {
    name: "Twitter",
    icon: (
      <XIcon className="w-5 h-5 fill-foreground group-hover:dark:fill-background group-hover:fill-white transition-colors duration-200" />
    ),
    link: "https://twitter.com/ai_icptg",
  },
  {
    name: "Medium",
    icon: (
      <MediumIcon className="w-5 h-5 fill-foreground group-hover:dark:fill-background group-hover:fill-white transition-colors duration-200" />
    ),
    link: "https://medium.com/@ICPTG",
  },
];

const SocialMedia = () => {
  const { ref, motionState } = useMotionRunning();
  const { ref: ref1, motionState: motionState1 } = useMotionRunning();
  const { ref: ref2, motionState: motionState2 } = useMotionRunning();
  const { ref: ref3, motionState: motionState3 } = useMotionRunning();
  const refs = [ref1, ref2, ref3];
  const motionStates = [motionState1, motionState2, motionState3];
  return (
    <div
      className=" website-container rounded-lg bg-secondary py-24 flex justify-center items-center"
      id="community"
    >
      <div className="flex flex-col justify-center items-center w-full h-full gap-12">
        <div
          ref={ref as RefObject<HTMLDivElement>}
          className={`flex flex-col gap-4 col-span-2 justify-center items-center ${motionState} `}
        >
          <p className="text-sm text-accentViolet">
            Become a part of our project
          </p>
          <h1 className=" text-2xl md:text-4xl font-semibold text-center">
            Intelliverse Community
          </h1>
        </div>
        <div className="grid grid-cols-3 gap-4 w-full">
          {data.map((sm, index) => (
            <a
              href={`${sm.link}`}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
              ref={refs[index] as RefObject<HTMLAnchorElement>}
              className={`h-36 bg-background rounded-lg flex flex-col col-span-3 md:col-span-1 group transition-all ${motionStates[index]} `}
            >
              <div className="flex w-full h-full basis-1/2 p-4 justify-between items-center ">
                <span className="w-12 h-12 border flex justify-center items-center rounded-full group-hover:dark:bg-white group-hover:bg-foreground group-hover:border-none transition-colors duration-200">
                  {sm.icon}
                </span>
                <span>
                  <ArrowRight className="w-8 h-8 group-hover:-rotate-45 duration-200 group-hover:w-10 group-hover:h-10" />
                </span>
              </div>
              <div className=" basis-1/2 flex justify-end items-end  p-4">
                <span className="text-sm font-semibold text-foreground">
                  {sm.name}
                </span>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SocialMedia;
