"use client";

import { Variants, motion } from "framer-motion";

const CircleSvgCounter = ({
  className = "",
  svgClassName = "",
  fixedCircleClassName = "",
  animatedCircleClassName = "",
  duration = 2000,
}) => {
  const circleVariants: Variants = {
    initial: {
      pathLength: 0,
    },
    animate: {
      pathLength: 100,
      transition: {
        duration: duration,
        ease: "linear",
      },
    },
  };

  return (
    <div className={` ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`lucide lucide-circle w-full h-full ${svgClassName} `}
      >
        <circle className={`${fixedCircleClassName}`} cx="12" cy="12" r="10" />
        <motion.circle
          className={`${animatedCircleClassName}`}
          variants={circleVariants}
          initial="initial"
          animate="animate"
          cx="12"
          cy="12"
          r="10"
          fill="none"
        />
      </svg>
    </div>
  );
};
export default CircleSvgCounter;
