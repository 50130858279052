"use client";
import { useMotionRunning } from "@/hooks/userMotionRunning";
import { dataEcosystem } from "./data";
import { RefObject } from "react";

const EcosystemItem = () => {
  const { ref: ref1, motionState: motionState1 } = useMotionRunning();
  const { ref: ref2, motionState: motionState2 } = useMotionRunning();
  const { ref: ref3, motionState: motionState3 } = useMotionRunning();
  const { ref: ref4, motionState: motionState4 } = useMotionRunning();
  const { ref: ref5, motionState: motionState5 } = useMotionRunning();
  const refs = [ref1, ref2, ref3, ref4, ref5];
  const motionStates = [
    motionState1,
    motionState2,
    motionState3,
    motionState4,
    motionState5,
  ];

  return (
    <div className="w-full grid grid-cols-2 gap-10 md:gap-20 ">
      {dataEcosystem.map((item, index) => (
        <div
          key={index}
          ref={refs[index] as RefObject<HTMLDivElement>}
          className={`w-full col-span-2 lg:col-span-1 border px-5 py-10 md:p-10 rounded-lg `}
        >
          {/* ${item.className} */}
          <div className={`flex flex-col gap-8 ${motionStates[index]} `}>
            <div className="w-full flex justify-between items-center">
              {item.icon}
              {item.badge}
            </div>
            <h2 className="text-left text-foreground text-lg font-bold md:text-lg ">
              {item.title}
            </h2>
            <div className="text-foreground/80 text-sm md:text-base">
              {item.description}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default EcosystemItem;
