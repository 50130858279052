import { motion, type MotionProps } from "framer-motion";

type MotionDivProps = MotionProps & React.HTMLAttributes<HTMLDivElement>;
type MotionSvgProps = MotionProps & React.SVGProps<SVGSVGElement>;
type MotionGProps = MotionProps & React.SVGProps<SVGGElement>;
type MotionCircleProps = MotionProps & React.SVGProps<SVGCircleElement>;
type MotionPathProps = MotionProps & React.SVGProps<SVGPathElement>;

export const MotionDiv = motion.div as React.FC<MotionDivProps>;
export const MotionSvg = motion.svg as React.FC<MotionSvgProps>;
export const MotionG = motion.g as React.FC<MotionGProps>;
export const MotionCircle = motion.circle as React.FC<MotionCircleProps>;
export const MotionPath = motion.path as React.FC<MotionPathProps>;
