"use client";
import { Progress } from "@/components/ui/progress";
import { useAppSelector } from "@/lib/hooks";
import { selectCurrentPhase } from "@/lib/slices/website/privateSaleSlice";
import { useReadContract } from "wagmi";
import { privateSaleSmartContract } from "@/wagmi/smartContract/privateSaleSmartContract";
import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";

type TPhaseInfo =
  | [bigint, boolean, bigint, bigint, bigint, bigint, bigint]
  | undefined;

const PrivateSaleProgress = () => {
  const currentPhase: number = useAppSelector(selectCurrentPhase);
  const [currentPhaseData, setCurrentPhaseData] = useState<TPhaseInfo>();
  const [progressValue, setProgressValue] = useState(0);
  const [totalTokensAllocated, setTotalTokensAllocated] = useState(0);
  const [maxTokenPerPhase, setMaxTokenPerPhase] = useState(2000_000);
  const {
    data: phaseInfo,
    isSuccess: phaseInfoIsSuccess,
    refetch: refetchPhaseInfo,
  } = useReadContract({
    ...privateSaleSmartContract,
    functionName: "phases",
    args: [currentPhase || 1],
  });

  useEffect(() => {
    if (phaseInfo) {
      setCurrentPhaseData(phaseInfo as TPhaseInfo);
    }
  }, [phaseInfo, phaseInfoIsSuccess]);

  useEffect(() => {
    if (currentPhaseData) {
      const bn = BigNumber(String(currentPhaseData[4]));
      const n = Number(bn.div(1e18).toFixed(2));
      setTotalTokensAllocated(n);
      const tttaBn = BigNumber(String(currentPhaseData[6]));
      const tttaN = Number(tttaBn.div(1e18).toFixed(1));
      setMaxTokenPerPhase(tttaN);
      setProgressValue((n / tttaN) * 100);
    }
  }, [currentPhaseData]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchPhaseInfo();
    }, 5000);
    return () => clearInterval(interval);
  }, [refetchPhaseInfo]);

  return (
    <div className="bg-background w-full mt-2 p-6 rounded-lg border md:border-none">
      <div className="relative flex flex-col">
        <h3 className="text-sm font-semibold mb-3 flex justify-between items-center ">
          <span>Private Sale Progress</span>
          {currentPhase !== 0 && (
            <span className="text-xs p-1 bg-accent/80 rounded-sm text-accentViolet dark:text-background">
              {`phase ${currentPhase}`}
            </span>
          )}
        </h3>
        <div className="flex justify-between text-xs text-muted-foreground py-1">
          <span>0</span>
          <span>{maxTokenPerPhase} ICPTG</span>
        </div>
        <div className="w-full">
          <Progress className="h-3" value={progressValue || 0} />
          {currentPhase !== 0 && (
            <div className="relative flex flex-nowrap">
              <div
                className={`spacer relative transition-all duration-1000 grow-0`}
                style={{
                  flexGrow: progressValue / 100,
                }}
              ></div>
              <p
                className={`slider relative grow-0 text-xs text-center flex justify-center items-center gap-1 mt-2`}
              >
                {totalTokensAllocated}
                <span className="text-xs">ICPTG</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default PrivateSaleProgress;
