"use client";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import PrivateSaleProgress from "./PrivateSaleProgress";
import BalanceTab from "./privateSaleComponent/BalanceTab";
import FormTab from "./privateSaleComponent/FormTab";
import PhasesTab from "./privateSaleComponent/PhasesTab";
import { Badge } from "@/components/ui/badge";
import WhiteList from "./privateSaleComponent/WhaiteList";
import { RefObject, useState } from "react";
import WhitelistProgress from "./privateSaleComponent/WhitelistProgress";
import { useMotionRunning } from "@/hooks/userMotionRunning";

const PrivateSaleForm = () => {
  const [isWhiteList, setIsWhiteList] = useState(false);
  const { ref, motionState } = useMotionRunning();
  return (
    <Tabs
      onValueChange={(activeTab: string) => {
        activeTab === "whiteList"
          ? setIsWhiteList(true)
          : setIsWhiteList(false);
      }}
      defaultValue="sale"
      className={`w-full md:bg-secondary p-6 rounded-lg motion-preset-blur-up-lg ${motionState} `}
      ref={ref as RefObject<HTMLDivElement>}
    >
      <TabsList className="grid w-full grid-cols-4 bg-background h-auto p-4 border md:border-none">
        <TabsTrigger className="py-3 text-xs md:text-sm " value="sale">
          Private Sale
        </TabsTrigger>
        <TabsTrigger
          className="py-3 text-xs md:text-sm relative "
          value="whiteList"
        >
          <span className="text-accentViolet dark:text-accent">White List</span>
          <Badge
            variant={"outlineAccent"}
            className="ml-2 absolute -top-1 -right-2 md:-right-1 text-[10px] border-none animate-pulse text-green-500 "
          >
            new
          </Badge>
        </TabsTrigger>
        <TabsTrigger className="py-3 text-xs md:text-sm " value="balance">
          Balance
        </TabsTrigger>
        <TabsTrigger className="py-3 text-xs md:text-sm " value="phases">
          Phases Info
        </TabsTrigger>
      </TabsList>
      <TabsContent value="sale">
        <FormTab />
      </TabsContent>
      <TabsContent value="whiteList">
        <WhiteList />
      </TabsContent>
      <TabsContent value="balance">
        <BalanceTab />
      </TabsContent>
      <TabsContent value="phases">
        <PhasesTab />
      </TabsContent>
      {isWhiteList ? <WhitelistProgress /> : <PrivateSaleProgress />}
    </Tabs>
  );
};
export default PrivateSaleForm;
