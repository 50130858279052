"use client";
import { RefObject, useRef } from "react";
import { partnersData } from "./data";
import { InfiniteMovingCardsPartners } from "../partials/InfiniteMovingCardsPartners";
import { useMotionRunning } from "@/hooks/userMotionRunning";

const Partners = () => {
  const { ref, motionState } = useMotionRunning();
  return (
    <div
      ref={ref as RefObject<HTMLDivElement>}
      className={`w-full rounded-lg bg- pb-24 flex flex-col justify-center items-center gap-4 ${motionState}`}
    >
      <InfiniteMovingCardsPartners
        items={partnersData}
        direction="left"
        speed="normal"
        className={`w-full`}
      />
      <InfiniteMovingCardsPartners
        items={partnersData}
        direction="right"
        speed="normal"
        className={`w-full`}
      />
    </div>
  );
};
export default Partners;
