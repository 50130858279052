"use client";
import { useMotionRunning } from "@/hooks/userMotionRunning";
import TokenomicChart from "./TokenomicChart";
import { tokenomicsData } from "./data";
import { RefObject } from "react";
import { m } from "framer-motion";

const Tokenomic = () => {
  const { ref, motionState } = useMotionRunning();
  const { ref: ref1, motionState: motionState1 } = useMotionRunning();
  const { ref: ref2, motionState: motionState2 } = useMotionRunning();
  const { ref: ref3, motionState: motionState3 } = useMotionRunning();
  const { ref: ref4, motionState: motionState4 } = useMotionRunning();
  const { ref: ref5, motionState: motionState5 } = useMotionRunning();
  const { ref: ref6, motionState: motionState6 } = useMotionRunning();
  const { ref: ref7, motionState: motionState7 } = useMotionRunning();
  const { ref: ref8, motionState: motionState8 } = useMotionRunning();
  const refs = [ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8];
  const motionStates = [
    motionState1,
    motionState2,
    motionState3,
    motionState4,
    motionState5,
    motionState6,
    motionState7,
    motionState8,
  ];
  return (
    <div className="website-container pb-24" id="tokenomic">
      <div
        ref={ref as RefObject<HTMLDivElement>}
        className={`relative flex flex-col gap-1 w-full justify-center items-center pb-24 ${motionState} `}
      >
        <span className="text-accentViolet">Tokenomic</span>
        <h2 className=" text-2xl md:text-4xl font-semibold w-full text-center">
          Token Distribution
        </h2>
        <p className="w-full md:w-1/2 text-center text-muted-foreground">
          Over the next two years, we are rolling out top-tier web3 tools and
          services. It is our plan to bring you the best in innovation and
          support. Stay tuned for an exciting journey ahead!
        </p>
      </div>
      <div className="grid grid-cols-2 w-full h-full gap-24">
        <div className="col-span-2  lg:col-span-1 w-full h-full flex justify-center items-center ">
          <div className="w-[600px] ">
            <TokenomicChart />
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1 w-full h-full">
          <div className="w-full bg-background rounded-lg grid grid-cols-2 gap-5 ">
            {tokenomicsData.map((item, i) => (
              <div
                ref={refs[i] as RefObject<HTMLDivElement>}
                key={i}
                className={` col-span-2 md:col-span-1 md:col-start-${item.cols?.start} md:col-end-${item.cols?.end} bg-background border p-3 rounded-lg flex flex-col justify-start items-center  w-full gap-3 ${motionStates[i]} `}
              >
                {/* <div
                  className={` absolute left-0 top-0 bottom-0 w-2 rounded-l-lg bg-${item.color}/50 `}
                ></div> */}
                <div
                  className={`${item.color} p-3 rounded-full flex justify-center items-center`}
                >
                  {item.icon}
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-sm text-center font-semibold ">
                    {item.title}
                  </p>
                  <p className="text-xs text-muted-foreground text-center  ">
                    {`${item.percentage} is equal ${item.tokensAttributed} ICPTG `}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Tokenomic;
