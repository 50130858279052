"use client";

import { useEffect, useState } from "react";
import { useReadContract } from "wagmi";
import BigNumber from "bignumber.js";
import { Skeleton } from "@/components/ui/skeleton";
import CircleSvgCounter from "@/components/shared/CircleSvgCounter";
import { useAppDispatch } from "@/lib/hooks";
import { setBNBPrice as setBNBPriceStore } from "@/lib/slices/website/privateSaleSlice";
import { privateSaleSmartContract } from "@/wagmi/smartContract/privateSaleSmartContract";

const BNBPrice = () => {
  const dispatch = useAppDispatch();
  const [bnbPrice, setBnbPrice] = useState<String>("0");
  const [isEnabled, setIsEnabled] = useState(true);
  const { data, isSuccess, isPending, refetch, isRefetching } = useReadContract(
    {
      ...privateSaleSmartContract,
      functionName: "getBNBPrice",
      args: [],
      query: {
        enabled: isEnabled,
      },
    }
  );

  useEffect(() => {
    isEnabled && setIsEnabled(false);
    const interval = setInterval(() => {
      refetch();
    }, 20_000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  useEffect(() => {
    if (isSuccess && data) {
      let priceBigInt = new BigNumber(data as BigNumber);
      dispatch(setBNBPriceStore(priceBigInt.toString()));
      const price = priceBigInt.div(1e18).toNumber();
      setBnbPrice(price.toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);
  return isPending || isRefetching ? (
    <Skeleton className="p-1 rounded-sm text-[12px] text-secondary bg-secondary w-36 h-6 "></Skeleton>
  ) : (
    <div className="p-1 rounded-sm text-[12px] text-accentViolet flex items-center gap-1 h-6">
      <CircleSvgCounter
        className="w-4 h-4"
        svgClassName=""
        fixedCircleClassName="stroke-[3] stroke-border"
        animatedCircleClassName="stroke-[3] stroke-accentViolet "
      />
      <span>{`1 BNB = ${bnbPrice} USDT`}</span>
    </div>
  );
};
export default BNBPrice;
