import {
  Database,
  Gem,
  Globe,
  Goal,
  Grid3X3,
  Layers,
  Shrub,
  Sprout,
  Target,
  Trophy,
  Users,
} from "lucide-react";

import type { JSX } from "react";

type TTokenomicsData = {
  title: string;
  percentage: string;
  tokensAttributed: string;
  icon: JSX.Element;
  color: string;
  cols?: {
    start: number;
    end: number;
  };
};

export const tokenomicsData: TTokenomicsData[] = [
  {
    title: "Marketing & Community Building",
    percentage: "5%",
    tokensAttributed: "5 000 000",
    icon: <Goal className="w-6 h-6 stroke-[1.4]" />,
    color: "bg-accent/50",
    cols: {
      start: 2,
      end: 4,
    },
  },
  // {
  //   title: "Strategic Partnerships & Collaborations",
  //   percentage: "5%",
  //   tokensAttributed: "1 000 000",
  //   icon: <Grid3X3 />,
  //   color: "accentOrange",
  // },
  {
    title: "Community Grants & Incentives",
    percentage: "5%",
    tokensAttributed: "5 000 000",
    icon: <Trophy className="w-6 h-6 stroke-[1.4] " />,
    color: "bg-accent/50",
    cols: {
      start: 2,
      end: 3,
    },
  },
  {
    title: "Staking Rewards",
    percentage: "5%",
    tokensAttributed: "5 000 000",
    icon: <Layers className="w-6 h-6 stroke-[1.4] " />,
    color: "bg-accent/50",
    cols: {
      start: 3,
      end: 4,
    },
  },
  {
    title: "Team",
    percentage: "5%",
    tokensAttributed: "5 000 000",
    icon: <Users className="w-6 h-6 stroke-[1.4] " />,
    color: "bg-accent/50",
    cols: {
      start: 4,
      end: 5,
    },
  },
  {
    title: "Ecosystem Growth Fund",
    percentage: "10%",
    tokensAttributed: "10 000 000",
    icon: <Sprout className="w-6 h-6 stroke-[1.4]" />,
    color: "bg-accentViolet/50",
    cols: {
      start: 5,
      end: 6,
    },
  },
  {
    title: "Private Sale",
    percentage: "10%",
    tokensAttributed: "10 000 000",
    icon: <Gem className="w-6 h-6 stroke-[1.4] " />,
    color: "bg-accentViolet/50",
    cols: {
      start: 6,
      end: 7,
    },
  },
  {
    title: "Public Sale",
    percentage: "30%",
    tokensAttributed: "30 000 000",
    icon: <Globe className="w-6 h-6 stroke-[1.4] " />,
    color: "bg-accentOrange/50",
    cols: {
      start: 7,
      end: 8,
    },
  },
  {
    title: "Liquidity Pools",
    percentage: "30%",
    tokensAttributed: "30 000 000",
    icon: <Database className="w-6 h-6 stroke-[1.4] " />,
    color: "bg-accentOrange/50",
    cols: {
      start: 8,
      end: 9,
    },
  },
];
